import { MockDraftType, UserLeague } from '../generated/graphql';

export type Option = {
  id: number;
  name: string;
};

const getOptions = (numbers: number[]) =>
  numbers.map(size => ({ id: size, name: `${size}` }));

const getTextOptions = (texts: string[]) =>
  texts.map((size, index) => ({ id: index, name: size }));

const maxStartUp = 440;
const maxRookie = 80;
const maxSFB = 12 * 22;

const autoClocks = getOptions([1, 2, 3, 5, 10]);
const userClocks = getOptions([10, 20, 30, 45, 120]);
const sizes = getOptions([8, 10, 12, 14, 16, 18, 20, 22, 24]);
const rounds = getOptions([
  4,
  6,
  8,
  10,
  12,
  14,
  16,
  18,
  20,
  22,
  24,
  26,
  28,
  30,
]);
const mockTypes = getTextOptions(['Rookie', 'Start-Up']);
const orderTypes = getTextOptions(['Linear', 'Snake']);

const teams12 = sizes.find(s => s.name === '12') as Option;
const rounds6 = rounds.find(s => s.name === '6') as Option;
const rounds12 = rounds.find(s => s.name === '12') as Option;
const rounds22 = rounds.find(s => s.name === '22') as Option;

const getSlots = (id: number) =>
  getOptions(Array.from({ length: id }, (x, i) => i + 1));

const slots = getSlots(teams12.id);

export type NewDraftState = {
  autoClock: Option;
  userClock: Option;
  type: MockDraftType;
  size: Option;
  round: Option;
  slot: Option;
  mockType: Option;
  orderType: Option;
  userLeague: UserLeague | null | undefined;
  leagues: UserLeague[];
  showLeagues: boolean;
  showScoring: boolean;
  showTeams: boolean;
  showRounds: boolean;
  showSlot: boolean;
  showOrder: boolean;
  playersNeeded: number;

  scoringTypes: MockDraftType[];
  mockDraftTypes: MockDraftType[];
  mockTypes: Option[];
  orderTypes: Option[];
  rounds: Option[];
  sizes: Option[];
  slots: Option[];
};

const getPlayersNeeded = (state: NewDraftState) => {
  return state.size.id * state.round.id;
};

const checkMax = (state: NewDraftState) => {
  let max = maxStartUp;
  if (state.mockType.name === 'Rookie') {
    max = maxRookie;
  }

  if (state.mockType.name === 'Scott Fish Bowl') {
    max = maxSFB;
  }
  const playersNeeded = getPlayersNeeded(state);
  const ok = max >= playersNeeded;

  const maxRounds = Math.floor(max / state.size.id);
  const newRounds = rounds.filter(r => r.id <= maxRounds);
  if (ok) {
    return {
      playersNeeded,
      rounds: newRounds,
    };
  }

  const changeRound = state.round?.id >= maxRounds;
  const newRound = changeRound ? newRounds[newRounds.length - 1] : state.round;
  return {
    playersNeeded,
    rounds: newRounds,
    round: newRound,
  };
};

const maxTeams = (state: NewDraftState) => {
  // const m = state.mockType.name === 'Rookie' ? 20 : 24;
  let m = 24;
  if (state.mockType.name === 'Rookie') {
    m = 20;
  }
  if (state.mockType.name === 'Scott Fish Bowl') {
    m = 12;
  }
  const newSizes = sizes.filter(s => s.id <= m) as Option[];
  const newSize =
    state.size.id > m ? (newSizes[newSizes.length - 1] as Option) : state.size;

  return {
    sizes: newSizes,
    size: newSize,
  };
};

const fixSlot = (state: NewDraftState) => {
  // changing the max rounds means we have to
  // limit the max slot
  // so if going from 12 to 10 rounds we have a new
  // max slot of 10
  const maxSlot = state.size.id;
  const newSlots = getSlots(maxSlot);
  const newSlot =
    state.slot.id > maxSlot ? newSlots[newSlots.length - 1] : state.slot;

  // if (state.mockType.name === 'Scott Fish Bowl') {
  //   [newSlot] = newSlots;
  // }

  return {
    slots: newSlots,
    slot: newSlot,
  };
};

export function getInit(
  mockDraftTypes: MockDraftType[],
  leagues: UserLeague[],
): NewDraftState {
  const scoringTypes = mockDraftTypes.filter(t => t.id !== 5);
  const newState = {
    autoClock: autoClocks[0],
    userClock: userClocks[2],
    type: scoringTypes[0],
    size: teams12,
    round: rounds12,
    slot: slots[0],
    mockType: mockTypes[0],
    orderType: orderTypes[1],
    userLeague: null,
    leagues,
    showLeagues: leagues.length > 0,
    showScoring: true,
    showTeams: true,
    showRounds: true,
    showSlot: true,
    showOrder: true,
    scoringTypes,
    mockDraftTypes,
    mockTypes,
    orderTypes,
    rounds,
    sizes,
    slots,
    playersNeeded: 0,
  };

  return { ...newState, playersNeeded: getPlayersNeeded(newState) };
}

export type ActionType =
  | 'Type'
  | 'Size'
  | 'Round'
  | 'Slot'
  | 'MockType'
  | 'OrderType'
  | 'League';

export const Actions = {
  Type: 'Type' as ActionType,
  Size: 'Size' as ActionType,
  Round: 'Round' as ActionType,
  Slot: 'Slot' as ActionType,
  MockType: 'MockType' as ActionType,
  OrderType: 'OrderType' as ActionType,
  League: 'League' as ActionType,
};

export type NewDraftAction = {
  action: ActionType;
  value: Option | MockDraftType | UserLeague | null | undefined;
};

const showLeagues = (state: NewDraftState, action: NewDraftAction): boolean => {
  return action.value?.name !== 'Scott Fish Bowl' && state.leagues.length > 0;
};

const notScottFish = (
  state: NewDraftState,
  action: NewDraftAction,
): boolean => {
  return action.value?.name !== 'Scott Fish Bowl';
};

const isRookie = (state: NewDraftState, action: NewDraftAction): boolean => {
  return state.mockType.name === 'Rookie';
};

const showTeams = (state: NewDraftState, action: NewDraftAction): boolean => {
  const hasLeague = state.userLeague !== null && state.userLeague !== undefined;

  if (hasLeague) return false;

  return action.value?.name !== 'Scott Fish Bowl';
};

const getOrderType = (state: NewDraftState, action: NewDraftAction): Option => {
  return state.mockType.name === 'Rookie'
    ? state.orderTypes[0]
    : state.orderTypes[1];
};

export const stateReducer = (
  state: NewDraftState,
  action: NewDraftAction,
): NewDraftState => {
  switch (action.action) {
    case Actions.MockType: {
      // first get the new state with type

      const newState = {
        ...state,
        mockType: action.value as Option,
        showLeagues: showLeagues(state, action),
        showScoring: notScottFish(state, action),
        showTeams: showTeams(state, action),
      };

      let round = rounds12;
      if (!notScottFish(newState, action)) {
        round = rounds22;
      } else if (isRookie(newState, action)) {
        round = rounds6;
      }
      // set round, order, show slots, show rounds and show order based on new type
      const newState2 = {
        ...newState,
        round,
        orderType: getOrderType(newState, action),
        showSlot: newState.userLeague === null || !isRookie(newState, action),
        showRounds:
          notScottFish(newState, action) &&
          (newState.userLeague === null || !isRookie(newState, action)),
        showOrder:
          notScottFish(newState, action) &&
          (newState.userLeague === null || !isRookie(newState, action)),
      };

      // adjust rounds and sized based off rookie or not
      const teamSizeAdjust = maxTeams(newState2);

      const newState25 = { ...newState2, ...teamSizeAdjust };
      const newState3 = {
        ...newState25,
        ...checkMax(newState25),
        ...teamSizeAdjust,
      };

      // if now SFB set scoring type
      if (!notScottFish(newState, action)) {
        const type = state.mockDraftTypes.find(
          t => t.name === 'Scott Fish Bowl',
        ) as MockDraftType;
        return { ...newState3, ...fixSlot(newState3), type };
      }

      // if was SFB now isn't set scoring type to PPR
      if (state.mockType.name === 'Scott Fish Bowl') {
        const type = state.mockDraftTypes.find(
          t => t.name === 'PPR',
        ) as MockDraftType;
        return { ...newState3, ...fixSlot(newState3), type };
      }

      const final = { ...newState3, ...fixSlot(newState3) };
      return final;
    }

    case Actions.League: {
      // if we are setting a league
      // we set type and size from league info
      // don't allow teams changes
      // only show rounds, slot and order if rookie
      // and just set them in the first slot

      // if not using a league
      // we just restrict based on scott fish bowl
      const userLeague = action.value as UserLeague | null | undefined;

      return userLeague
        ? {
          ...state,
          userLeague,
          type: state.scoringTypes.find(
            t => t.id === (action.value as UserLeague).mockDraftTypeId,
          ) as MockDraftType,
          size: {
            id: userLeague.teams.length,
            name: `${userLeague.teams.length}`,
          },
          showTeams: false,
          showRounds: !isRookie(state, action),
          showSlot: !isRookie(state, action),
          showOrder: !isRookie(state, action),
          slot: {
            id: 1,
            name: '1',
          },
        }
        : {
          ...state,
          userLeague,
          showTeams: notScottFish(state, action),
          showRounds: notScottFish(state, action),
          showSlot: true,
          showOrder: notScottFish(state, action),
        };
    }

    case Actions.Type:
      return {
        ...state,
        type: action.value as MockDraftType,
      };

    case Actions.Size: {
      const newState = {
        ...state,
        size: action.value as Option,
      };
      const sizeAdjust = checkMax(newState);
      const newState2 = { ...newState, ...sizeAdjust };
      return { ...newState2, ...fixSlot(newState2) };
    }

    case Actions.Round: {
      const newState = {
        ...state,
        round: action.value as Option,
      };

      return { ...newState, ...fixSlot(newState) };
    }

    case Actions.Slot:
      return {
        ...state,
        slot: action.value as Option,
      };

    case Actions.OrderType:
      return {
        ...state,
        orderType: action.value as Option,
      };

    default:
      console.error(`${action.action} action not implemented`);
      return state;
  }
};

import React from 'react';
import { TextInput, View } from 'react-native';
import { tailwindCss } from '../../common/tailwind';
import NerdButton from '../NerdButton';
import WebScrollHandler from './WebScrollHandler';

export interface IPositionTogglerProps {
  setFilter: any;
  filter: {
    postition: string[];
    term: string;
    search: boolean;
  };

  onPressUp?: (event: any) => void;
  onPressDown?: (event: any) => void;
}

export const getPositionTextColor = (position: string) => {
  if (position === 'QB') {
    return 'text-red-500';
  }
  if (position === 'RB') {
    return 'text-green-500';
  }
  if (position === 'WR') {
    return 'text-orange-500';
  }
  return 'text-blue-500';
};

export const getBgColor = (position: string) => {
  if (position === 'QB') {
    return 'bg-red-500';
  }
  if (position === 'RB') {
    return 'bg-green-500';
  }
  if (position === 'WR') {
    return 'bg-orange-500';
  }
  return 'bg-blue-500';
};

export default function PositionToggler({
  setFilter,
  filter,
  onPressDown,
  onPressUp,
}: IPositionTogglerProps) {
  const togglePosition = (position: string) => {
    const selectedCount = filter.postition.length;
    if (selectedCount === 4) {
      setFilter({
        ...filter,
        search: false,
        postition: [position],
      });
    } else if (filter.postition.includes(position)) {
      setFilter({
        ...filter,
        search: false,
        postition: filter.postition.filter(p => p !== position),
      });
    } else {
      setFilter({
        ...filter,
        search: false,
        postition: [...filter.postition, position],
      });
    }
  };

  const getColorFilter = (position: string) => {
    if (!filter.postition.includes(position)) return 'bg-white';

    return getBgColor(position);
  };
  const getTextColor = (position: string) => {
    if (filter.postition.includes(position)) return 'text-white';
    return 'text-gray-500';
  };
  const rnStyle = {
    paddingLeft: 18,
    paddingRight: 18,
    paddingTop: 16,
    paddingBottom: 16,
  };
  return (
    <>
      <WebScrollHandler upHandler={onPressUp} downHandler={onPressDown}>
        <View
          style={tailwindCss(
            `flex flex-row justify-between items-center p-3 ${
              filter.search ? '' : 'border-b border-gray-300'
            } `,
          )}
        >
          <View>
            <NerdButton
              padding=" "
              paddingX=" "
              rnStyle={rnStyle}
              additional="mb-1"
              color={getColorFilter('QB')}
              textColor={getTextColor('QB')}
              onPress={() => {
                togglePosition('QB');
              }}
            >
              QB
            </NerdButton>
          </View>
          <View>
            <NerdButton
              padding=" "
              paddingX=" "
              rnStyle={rnStyle}
              color={getColorFilter('RB')}
              textColor={getTextColor('RB')}
              onPress={() => {
                togglePosition('RB');
              }}
            >
              RB
            </NerdButton>
          </View>
          <View>
            <NerdButton
              padding=" "
              paddingX=" "
              rnStyle={rnStyle}
              color={getColorFilter('WR')}
              textColor={getTextColor('WR')}
              onPress={() => {
                togglePosition('WR');
              }}
            >
              WR
            </NerdButton>
          </View>
          <View>
            <NerdButton
              padding=""
              paddingX=""
              rnStyle={rnStyle}
              color={getColorFilter('TE')}
              textColor={getTextColor('TE')}
              onPress={() => {
                togglePosition('TE');
              }}
            >
              TE
            </NerdButton>
          </View>
          <View>
            <NerdButton
              width=""
              color="bg-gray-800"
              onPress={() => {
                setFilter({ ...filter, search: !filter.search });
              }}
            >
              Search
            </NerdButton>
          </View>
        </View>
      </WebScrollHandler>
      {filter.search && (
        <View
          style={tailwindCss(
            'flex-row justify-between items-center px-3 pt-3 mt-1 border-b border-gray-300',
          )}
        >
          <View
            style={tailwindCss(
              'bg-white p-3 w-full mb-2 border border-gray-300 rounded',
            )}
          >
            <TextInput
              clearButtonMode="always"
              placeholder="Search"
              onChangeText={(text: string) => {
                setFilter({ ...filter, term: text });
              }}
              value={filter.term}
              autoCompleteType="name"
              textContentType="username"
            />
          </View>
        </View>
      )}
    </>
  );
}

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Draft = {
  __typename?: 'Draft';
  autoLength: Scalars['Int'];
  clockLength: Scalars['Int'];
  clockMs: Scalars['Int'];
  draftPicks: Array<DraftPick>;
  id: Scalars['Int'];
  key: Scalars['String'];
  players: Array<Player>;
  rookiesOnly: Scalars['Boolean'];
  status: Scalars['String'];
  typeId: Scalars['Int'];
  users: Array<User>;
};

export type DraftPick = {
  __typename?: 'DraftPick';
  id: Scalars['Int'];
  originalUserId: Scalars['Int'];
  overall: Scalars['Int'];
  player?: Maybe<Player>;
  realPick: Scalars['Boolean'];
  round: Scalars['Int'];
  slot: Scalars['Int'];
  userId: Scalars['Int'];
};

export type DraftPickInput = {
  id: Scalars['Int'];
  playerId?: Maybe<Scalars['Int']>;
  realPick: Scalars['Boolean'];
  userId: Scalars['Int'];
};

export type InputError = {
  __typename?: 'InputError';
  key: Scalars['String'];
  message: Scalars['String'];
};

export type LeagueTeam = {
  __typename?: 'LeagueTeam';
  id: Scalars['Int'];
  name: Scalars['String'];
  owned: Scalars['Boolean'];
};

export type MockDraftSyncInput = {
  draftPicks: Array<DraftPickInput>;
  id: Scalars['Int'];
  status: Scalars['String'];
};

export type MockDraftType = {
  __typename?: 'MockDraftType';
  id: Scalars['Int'];
  name: Scalars['String'];
  rankinTypeId: Scalars['Int'];
};

export type NewMockDraftParams = {
  autoLength: Scalars['Int'];
  clockLength: Scalars['Int'];
  clockMs: Scalars['Int'];
  leagueId?: Maybe<Scalars['Int']>;
  noAdp?: Maybe<Scalars['Boolean']>;
  orderType: Scalars['String'];
  position?: Maybe<Scalars['Int']>;
  random: Scalars['Boolean'];
  rookiesOnly: Scalars['Boolean'];
  rounds: Scalars['Int'];
  size?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
  typeId: Scalars['Int'];
  useLeagueDraftData: Scalars['Boolean'];
};

export type Player = {
  __typename?: 'Player';
  id: Scalars['Int'];
  img: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
  playerId: Scalars['Int'];
  position: Scalars['String'];
  rookie: Scalars['Boolean'];
  team: Scalars['String'];
};

export type Result = {
  __typename?: 'Result';
  errors: Array<InputError>;
  status?: Maybe<Scalars['String']>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  claimPick: Result;
  clientMockDraftSync: Result;
  expirePick: Result;
  joinMockDraft: Draft;
  /** Create New Draft */
  newClientMockDraft: Draft;
  newMockDraft: Draft;
  pauseClock: Result;
  resumeClock: Result;
  updateMockDraftPick: Result;
  updateMockDraftStatus: Result;
};


export type RootMutationTypeClaimPickArgs = {
  draftPickId: Scalars['Int'];
  key: Scalars['String'];
  userId: Scalars['Int'];
};


export type RootMutationTypeClientMockDraftSyncArgs = {
  mockDraft: MockDraftSyncInput;
};


export type RootMutationTypeExpirePickArgs = {
  draftPickId: Scalars['Int'];
  key: Scalars['String'];
};


export type RootMutationTypeJoinMockDraftArgs = {
  key: Scalars['String'];
};


export type RootMutationTypeNewClientMockDraftArgs = {
  newMockDraft: NewMockDraftParams;
};


export type RootMutationTypeNewMockDraftArgs = {
  newMockDraft: NewMockDraftParams;
};


export type RootMutationTypePauseClockArgs = {
  key: Scalars['String'];
  remainingMs: Scalars['Int'];
};


export type RootMutationTypeResumeClockArgs = {
  key: Scalars['String'];
};


export type RootMutationTypeUpdateMockDraftPickArgs = {
  key: Scalars['String'];
  pickId: Scalars['Int'];
  playerId: Scalars['Int'];
};


export type RootMutationTypeUpdateMockDraftStatusArgs = {
  key: Scalars['String'];
  status: Scalars['String'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  /** Get an Existing Mock Draft */
  clientMockDraft: Draft;
  /** Mock Draft Types */
  mockDraftTypes: Array<MockDraftType>;
  userDrafts: Array<UserDraft>;
  /** User info */
  userInfo?: Maybe<UserInfo>;
  /** User Leagues */
  userLeagues: Array<UserLeague>;
};


export type RootQueryTypeClientMockDraftArgs = {
  id: Scalars['Int'];
};

export type RootSubscriptionType = {
  __typename?: 'RootSubscriptionType';
  /** Join the Mock draft to receive updates */
  enterMockDraft: Draft;
};


export type RootSubscriptionTypeEnterMockDraftArgs = {
  key: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  auto: Scalars['Boolean'];
  id: Scalars['Int'];
  name: Scalars['String'];
  online: Scalars['Boolean'];
  players: Array<UserPlayer>;
  teamName: Scalars['String'];
  userId: Scalars['Int'];
};

export type UserDraft = {
  __typename?: 'UserDraft';
  id: Scalars['Int'];
  insertedAt: Scalars['String'];
  key: Scalars['String'];
  league?: Maybe<Scalars['String']>;
  leagueId?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  rookiesOnly: Scalars['Boolean'];
  rounds: Scalars['Int'];
  size?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  typeId: Scalars['Int'];
  useLeagueDraftData: Scalars['Boolean'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  email: Scalars['String'];
  extId: Scalars['String'];
  id: Scalars['Int'];
  impersonator?: Maybe<Scalars['String']>;
  isAdmin: Scalars['Boolean'];
  isDynastygm: Scalars['Boolean'];
  isEditor: Scalars['Boolean'];
  isGm: Scalars['Boolean'];
  isNerd: Scalars['Boolean'];
  isPremium: Scalars['Boolean'];
  isRanker: Scalars['Boolean'];
  isTrial: Scalars['Boolean'];
  login: Scalars['String'];
  name: Scalars['String'];
  nicename: Scalars['String'];
  status: Scalars['String'];
  trialEnds?: Maybe<Scalars['String']>;
};

export type UserLeague = {
  __typename?: 'UserLeague';
  id: Scalars['Int'];
  mockDraftTypeId: Scalars['Int'];
  name: Scalars['String'];
  teams: Array<LeagueTeam>;
};

export type UserPlayer = {
  __typename?: 'UserPlayer';
  avg: Scalars['Float'];
  id: Scalars['Int'];
  img: Scalars['String'];
  name: Scalars['String'];
  position: Scalars['String'];
  rookie: Scalars['Boolean'];
  team: Scalars['String'];
};

export type ClaimPickMutationVariables = Exact<{
  key: Scalars['String'];
  draftPickId: Scalars['Int'];
  userId: Scalars['Int'];
}>;


export type ClaimPickMutation = (
  { __typename?: 'RootMutationType' }
  & { claimPick: (
    { __typename?: 'Result' }
    & Pick<Result, 'status'>
    & { errors: Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'key' | 'message'>
    )> }
  ) }
);

export type ExpirePickMutationVariables = Exact<{
  key: Scalars['String'];
  draftPickId: Scalars['Int'];
}>;


export type ExpirePickMutation = (
  { __typename?: 'RootMutationType' }
  & { expirePick: (
    { __typename?: 'Result' }
    & Pick<Result, 'status'>
    & { errors: Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'key' | 'message'>
    )> }
  ) }
);

export type JoinMockDraftMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type JoinMockDraftMutation = (
  { __typename?: 'RootMutationType' }
  & { joinMockDraft: (
    { __typename?: 'Draft' }
    & Pick<Draft, 'autoLength' | 'clockLength' | 'clockMs' | 'id' | 'key' | 'status'>
    & { draftPicks: Array<(
      { __typename?: 'DraftPick' }
      & Pick<DraftPick, 'id' | 'overall' | 'round' | 'slot' | 'userId'>
      & { player?: Maybe<(
        { __typename?: 'Player' }
        & Pick<Player, 'id'>
      )> }
    )>, players: Array<(
      { __typename?: 'Player' }
      & Pick<Player, 'id' | 'img' | 'name' | 'position' | 'rookie' | 'team'>
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'auto' | 'id' | 'name' | 'teamName' | 'online'>
      & { players: Array<(
        { __typename?: 'UserPlayer' }
        & Pick<UserPlayer, 'id'>
      )> }
    )> }
  ) }
);

export type NewClientMockDraftMutationVariables = Exact<{
  newMockDraft: NewMockDraftParams;
}>;


export type NewClientMockDraftMutation = (
  { __typename?: 'RootMutationType' }
  & { newClientMockDraft: (
    { __typename?: 'Draft' }
    & Pick<Draft, 'autoLength' | 'clockLength' | 'clockMs' | 'id' | 'key' | 'rookiesOnly' | 'status'>
    & { draftPicks: Array<(
      { __typename?: 'DraftPick' }
      & Pick<DraftPick, 'id' | 'overall' | 'round' | 'slot' | 'userId' | 'originalUserId'>
      & { player?: Maybe<(
        { __typename?: 'Player' }
        & Pick<Player, 'id' | 'img' | 'name' | 'position' | 'rookie' | 'team'>
      )> }
    )>, players: Array<(
      { __typename?: 'Player' }
      & Pick<Player, 'id' | 'img' | 'name' | 'position' | 'rookie' | 'team'>
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'auto' | 'id' | 'name' | 'teamName' | 'online' | 'userId'>
      & { players: Array<(
        { __typename?: 'UserPlayer' }
        & Pick<UserPlayer, 'id' | 'img' | 'name' | 'position' | 'rookie' | 'team'>
      )> }
    )> }
  ) }
);

export type NewMockDraftMutationVariables = Exact<{
  newMockDraft: NewMockDraftParams;
}>;


export type NewMockDraftMutation = (
  { __typename?: 'RootMutationType' }
  & { newMockDraft: (
    { __typename?: 'Draft' }
    & Pick<Draft, 'id' | 'key'>
  ) }
);

export type PauseClockMutationVariables = Exact<{
  key: Scalars['String'];
  remainingMs: Scalars['Int'];
}>;


export type PauseClockMutation = (
  { __typename?: 'RootMutationType' }
  & { pauseClock: (
    { __typename?: 'Result' }
    & Pick<Result, 'status'>
    & { errors: Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'key' | 'message'>
    )> }
  ) }
);

export type ResumeClockMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type ResumeClockMutation = (
  { __typename?: 'RootMutationType' }
  & { resumeClock: (
    { __typename?: 'Result' }
    & Pick<Result, 'status'>
    & { errors: Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'key' | 'message'>
    )> }
  ) }
);

export type ClientMockDraftSyncMutationVariables = Exact<{
  mockDraft: MockDraftSyncInput;
}>;


export type ClientMockDraftSyncMutation = (
  { __typename?: 'RootMutationType' }
  & { clientMockDraftSync: (
    { __typename?: 'Result' }
    & Pick<Result, 'status'>
    & { errors: Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'key' | 'message'>
    )> }
  ) }
);

export type UpdateMockDraftPickMutationVariables = Exact<{
  key: Scalars['String'];
  playerId: Scalars['Int'];
  pickId: Scalars['Int'];
}>;


export type UpdateMockDraftPickMutation = (
  { __typename?: 'RootMutationType' }
  & { updateMockDraftPick: (
    { __typename?: 'Result' }
    & Pick<Result, 'status'>
    & { errors: Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'key' | 'message'>
    )> }
  ) }
);

export type UpdateMockDraftStatusMutationVariables = Exact<{
  key: Scalars['String'];
  status: Scalars['String'];
}>;


export type UpdateMockDraftStatusMutation = (
  { __typename?: 'RootMutationType' }
  & { updateMockDraftStatus: (
    { __typename?: 'Result' }
    & Pick<Result, 'status'>
    & { errors: Array<(
      { __typename?: 'InputError' }
      & Pick<InputError, 'key' | 'message'>
    )> }
  ) }
);

export type ClientMockDraftQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ClientMockDraftQuery = (
  { __typename?: 'RootQueryType' }
  & { clientMockDraft: (
    { __typename?: 'Draft' }
    & Pick<Draft, 'autoLength' | 'clockLength' | 'clockMs' | 'id' | 'key' | 'rookiesOnly' | 'status' | 'typeId'>
    & { draftPicks: Array<(
      { __typename?: 'DraftPick' }
      & Pick<DraftPick, 'id' | 'overall' | 'round' | 'slot' | 'userId' | 'originalUserId' | 'realPick'>
      & { player?: Maybe<(
        { __typename?: 'Player' }
        & Pick<Player, 'id' | 'img' | 'name' | 'position' | 'rookie' | 'team'>
      )> }
    )>, players: Array<(
      { __typename?: 'Player' }
      & Pick<Player, 'id' | 'img' | 'name' | 'position' | 'rookie' | 'team' | 'order' | 'playerId'>
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'auto' | 'id' | 'name' | 'teamName' | 'online' | 'userId'>
      & { players: Array<(
        { __typename?: 'UserPlayer' }
        & Pick<UserPlayer, 'id' | 'img' | 'name' | 'position' | 'rookie' | 'team'>
      )> }
    )> }
  ) }
);

export type MockDraftTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type MockDraftTypesQuery = (
  { __typename?: 'RootQueryType' }
  & { mockDraftTypes: Array<(
    { __typename?: 'MockDraftType' }
    & Pick<MockDraftType, 'id' | 'name' | 'rankinTypeId'>
  )> }
);

export type UserDraftsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserDraftsQuery = (
  { __typename?: 'RootQueryType' }
  & { userDrafts: Array<(
    { __typename?: 'UserDraft' }
    & Pick<UserDraft, 'id' | 'insertedAt' | 'key' | 'league' | 'leagueId' | 'position' | 'rookiesOnly' | 'rounds' | 'size' | 'status' | 'title' | 'type' | 'typeId'>
  )> }
);

export type UserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { userInfo?: Maybe<(
    { __typename?: 'UserInfo' }
    & Pick<UserInfo, 'email' | 'id' | 'impersonator' | 'isAdmin' | 'isDynastygm' | 'isEditor' | 'isGm' | 'isNerd' | 'isPremium' | 'isRanker' | 'isTrial' | 'login' | 'name' | 'nicename' | 'status' | 'trialEnds'>
  )> }
);

export type UserLeaguesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserLeaguesQuery = (
  { __typename?: 'RootQueryType' }
  & { userLeagues: Array<(
    { __typename?: 'UserLeague' }
    & Pick<UserLeague, 'id' | 'name' | 'mockDraftTypeId'>
    & { teams: Array<(
      { __typename?: 'LeagueTeam' }
      & Pick<LeagueTeam, 'id' | 'name' | 'owned'>
    )> }
  )> }
);

export type EnterMockDraftSubscriptionVariables = Exact<{
  key: Scalars['String'];
}>;


export type EnterMockDraftSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { enterMockDraft: (
    { __typename?: 'Draft' }
    & Pick<Draft, 'id' | 'key' | 'status'>
    & { draftPicks: Array<(
      { __typename?: 'DraftPick' }
      & Pick<DraftPick, 'id' | 'overall' | 'round' | 'slot' | 'userId'>
      & { player?: Maybe<(
        { __typename?: 'Player' }
        & Pick<Player, 'id'>
      )> }
    )>, users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'auto' | 'id' | 'name' | 'teamName'>
    )> }
  ) }
);


export const ClaimPickDocument = gql`
    mutation ClaimPick($key: String!, $draftPickId: Int!, $userId: Int!) {
  claimPick(key: $key, draftPickId: $draftPickId, userId: $userId) {
    status
    errors {
      key
      message
    }
  }
}
    `;
export type ClaimPickMutationFn = Apollo.MutationFunction<ClaimPickMutation, ClaimPickMutationVariables>;

/**
 * __useClaimPickMutation__
 *
 * To run a mutation, you first call `useClaimPickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimPickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimPickMutation, { data, loading, error }] = useClaimPickMutation({
 *   variables: {
 *      key: // value for 'key'
 *      draftPickId: // value for 'draftPickId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useClaimPickMutation(baseOptions?: Apollo.MutationHookOptions<ClaimPickMutation, ClaimPickMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimPickMutation, ClaimPickMutationVariables>(ClaimPickDocument, options);
      }
export type ClaimPickMutationHookResult = ReturnType<typeof useClaimPickMutation>;
export type ClaimPickMutationResult = Apollo.MutationResult<ClaimPickMutation>;
export type ClaimPickMutationOptions = Apollo.BaseMutationOptions<ClaimPickMutation, ClaimPickMutationVariables>;
export const ExpirePickDocument = gql`
    mutation ExpirePick($key: String!, $draftPickId: Int!) {
  expirePick(key: $key, draftPickId: $draftPickId) {
    status
    errors {
      key
      message
    }
  }
}
    `;
export type ExpirePickMutationFn = Apollo.MutationFunction<ExpirePickMutation, ExpirePickMutationVariables>;

/**
 * __useExpirePickMutation__
 *
 * To run a mutation, you first call `useExpirePickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExpirePickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [expirePickMutation, { data, loading, error }] = useExpirePickMutation({
 *   variables: {
 *      key: // value for 'key'
 *      draftPickId: // value for 'draftPickId'
 *   },
 * });
 */
export function useExpirePickMutation(baseOptions?: Apollo.MutationHookOptions<ExpirePickMutation, ExpirePickMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExpirePickMutation, ExpirePickMutationVariables>(ExpirePickDocument, options);
      }
export type ExpirePickMutationHookResult = ReturnType<typeof useExpirePickMutation>;
export type ExpirePickMutationResult = Apollo.MutationResult<ExpirePickMutation>;
export type ExpirePickMutationOptions = Apollo.BaseMutationOptions<ExpirePickMutation, ExpirePickMutationVariables>;
export const JoinMockDraftDocument = gql`
    mutation JoinMockDraft($key: String!) {
  joinMockDraft(key: $key) {
    autoLength
    clockLength
    clockMs
    draftPicks {
      id
      overall
      player {
        id
      }
      round
      slot
      userId
    }
    id
    key
    players {
      id
      img
      name
      position
      rookie
      team
    }
    status
    users {
      auto
      id
      name
      players {
        id
      }
      teamName
      online
    }
  }
}
    `;
export type JoinMockDraftMutationFn = Apollo.MutationFunction<JoinMockDraftMutation, JoinMockDraftMutationVariables>;

/**
 * __useJoinMockDraftMutation__
 *
 * To run a mutation, you first call `useJoinMockDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinMockDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinMockDraftMutation, { data, loading, error }] = useJoinMockDraftMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useJoinMockDraftMutation(baseOptions?: Apollo.MutationHookOptions<JoinMockDraftMutation, JoinMockDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JoinMockDraftMutation, JoinMockDraftMutationVariables>(JoinMockDraftDocument, options);
      }
export type JoinMockDraftMutationHookResult = ReturnType<typeof useJoinMockDraftMutation>;
export type JoinMockDraftMutationResult = Apollo.MutationResult<JoinMockDraftMutation>;
export type JoinMockDraftMutationOptions = Apollo.BaseMutationOptions<JoinMockDraftMutation, JoinMockDraftMutationVariables>;
export const NewClientMockDraftDocument = gql`
    mutation NewClientMockDraft($newMockDraft: NewMockDraftParams!) {
  newClientMockDraft(newMockDraft: $newMockDraft) {
    autoLength
    clockLength
    clockMs
    draftPicks {
      id
      overall
      player {
        id
        img
        name
        position
        rookie
        team
      }
      round
      slot
      userId
      originalUserId
    }
    id
    key
    players {
      id
      img
      name
      position
      rookie
      team
    }
    rookiesOnly
    status
    users {
      auto
      id
      name
      players {
        id
        img
        name
        position
        rookie
        team
      }
      teamName
      online
      userId
    }
  }
}
    `;
export type NewClientMockDraftMutationFn = Apollo.MutationFunction<NewClientMockDraftMutation, NewClientMockDraftMutationVariables>;

/**
 * __useNewClientMockDraftMutation__
 *
 * To run a mutation, you first call `useNewClientMockDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewClientMockDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newClientMockDraftMutation, { data, loading, error }] = useNewClientMockDraftMutation({
 *   variables: {
 *      newMockDraft: // value for 'newMockDraft'
 *   },
 * });
 */
export function useNewClientMockDraftMutation(baseOptions?: Apollo.MutationHookOptions<NewClientMockDraftMutation, NewClientMockDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewClientMockDraftMutation, NewClientMockDraftMutationVariables>(NewClientMockDraftDocument, options);
      }
export type NewClientMockDraftMutationHookResult = ReturnType<typeof useNewClientMockDraftMutation>;
export type NewClientMockDraftMutationResult = Apollo.MutationResult<NewClientMockDraftMutation>;
export type NewClientMockDraftMutationOptions = Apollo.BaseMutationOptions<NewClientMockDraftMutation, NewClientMockDraftMutationVariables>;
export const NewMockDraftDocument = gql`
    mutation NewMockDraft($newMockDraft: NewMockDraftParams!) {
  newMockDraft(newMockDraft: $newMockDraft) {
    id
    key
  }
}
    `;
export type NewMockDraftMutationFn = Apollo.MutationFunction<NewMockDraftMutation, NewMockDraftMutationVariables>;

/**
 * __useNewMockDraftMutation__
 *
 * To run a mutation, you first call `useNewMockDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewMockDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newMockDraftMutation, { data, loading, error }] = useNewMockDraftMutation({
 *   variables: {
 *      newMockDraft: // value for 'newMockDraft'
 *   },
 * });
 */
export function useNewMockDraftMutation(baseOptions?: Apollo.MutationHookOptions<NewMockDraftMutation, NewMockDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewMockDraftMutation, NewMockDraftMutationVariables>(NewMockDraftDocument, options);
      }
export type NewMockDraftMutationHookResult = ReturnType<typeof useNewMockDraftMutation>;
export type NewMockDraftMutationResult = Apollo.MutationResult<NewMockDraftMutation>;
export type NewMockDraftMutationOptions = Apollo.BaseMutationOptions<NewMockDraftMutation, NewMockDraftMutationVariables>;
export const PauseClockDocument = gql`
    mutation PauseClock($key: String!, $remainingMs: Int!) {
  pauseClock(key: $key, remainingMs: $remainingMs) {
    status
    errors {
      key
      message
    }
  }
}
    `;
export type PauseClockMutationFn = Apollo.MutationFunction<PauseClockMutation, PauseClockMutationVariables>;

/**
 * __usePauseClockMutation__
 *
 * To run a mutation, you first call `usePauseClockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePauseClockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pauseClockMutation, { data, loading, error }] = usePauseClockMutation({
 *   variables: {
 *      key: // value for 'key'
 *      remainingMs: // value for 'remainingMs'
 *   },
 * });
 */
export function usePauseClockMutation(baseOptions?: Apollo.MutationHookOptions<PauseClockMutation, PauseClockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PauseClockMutation, PauseClockMutationVariables>(PauseClockDocument, options);
      }
export type PauseClockMutationHookResult = ReturnType<typeof usePauseClockMutation>;
export type PauseClockMutationResult = Apollo.MutationResult<PauseClockMutation>;
export type PauseClockMutationOptions = Apollo.BaseMutationOptions<PauseClockMutation, PauseClockMutationVariables>;
export const ResumeClockDocument = gql`
    mutation ResumeClock($key: String!) {
  resumeClock(key: $key) {
    status
    errors {
      key
      message
    }
  }
}
    `;
export type ResumeClockMutationFn = Apollo.MutationFunction<ResumeClockMutation, ResumeClockMutationVariables>;

/**
 * __useResumeClockMutation__
 *
 * To run a mutation, you first call `useResumeClockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeClockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeClockMutation, { data, loading, error }] = useResumeClockMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useResumeClockMutation(baseOptions?: Apollo.MutationHookOptions<ResumeClockMutation, ResumeClockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeClockMutation, ResumeClockMutationVariables>(ResumeClockDocument, options);
      }
export type ResumeClockMutationHookResult = ReturnType<typeof useResumeClockMutation>;
export type ResumeClockMutationResult = Apollo.MutationResult<ResumeClockMutation>;
export type ResumeClockMutationOptions = Apollo.BaseMutationOptions<ResumeClockMutation, ResumeClockMutationVariables>;
export const ClientMockDraftSyncDocument = gql`
    mutation ClientMockDraftSync($mockDraft: MockDraftSyncInput!) {
  clientMockDraftSync(mockDraft: $mockDraft) {
    status
    errors {
      key
      message
    }
  }
}
    `;
export type ClientMockDraftSyncMutationFn = Apollo.MutationFunction<ClientMockDraftSyncMutation, ClientMockDraftSyncMutationVariables>;

/**
 * __useClientMockDraftSyncMutation__
 *
 * To run a mutation, you first call `useClientMockDraftSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClientMockDraftSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clientMockDraftSyncMutation, { data, loading, error }] = useClientMockDraftSyncMutation({
 *   variables: {
 *      mockDraft: // value for 'mockDraft'
 *   },
 * });
 */
export function useClientMockDraftSyncMutation(baseOptions?: Apollo.MutationHookOptions<ClientMockDraftSyncMutation, ClientMockDraftSyncMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClientMockDraftSyncMutation, ClientMockDraftSyncMutationVariables>(ClientMockDraftSyncDocument, options);
      }
export type ClientMockDraftSyncMutationHookResult = ReturnType<typeof useClientMockDraftSyncMutation>;
export type ClientMockDraftSyncMutationResult = Apollo.MutationResult<ClientMockDraftSyncMutation>;
export type ClientMockDraftSyncMutationOptions = Apollo.BaseMutationOptions<ClientMockDraftSyncMutation, ClientMockDraftSyncMutationVariables>;
export const UpdateMockDraftPickDocument = gql`
    mutation UpdateMockDraftPick($key: String!, $playerId: Int!, $pickId: Int!) {
  updateMockDraftPick(key: $key, playerId: $playerId, pickId: $pickId) {
    status
    errors {
      key
      message
    }
  }
}
    `;
export type UpdateMockDraftPickMutationFn = Apollo.MutationFunction<UpdateMockDraftPickMutation, UpdateMockDraftPickMutationVariables>;

/**
 * __useUpdateMockDraftPickMutation__
 *
 * To run a mutation, you first call `useUpdateMockDraftPickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMockDraftPickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMockDraftPickMutation, { data, loading, error }] = useUpdateMockDraftPickMutation({
 *   variables: {
 *      key: // value for 'key'
 *      playerId: // value for 'playerId'
 *      pickId: // value for 'pickId'
 *   },
 * });
 */
export function useUpdateMockDraftPickMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMockDraftPickMutation, UpdateMockDraftPickMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMockDraftPickMutation, UpdateMockDraftPickMutationVariables>(UpdateMockDraftPickDocument, options);
      }
export type UpdateMockDraftPickMutationHookResult = ReturnType<typeof useUpdateMockDraftPickMutation>;
export type UpdateMockDraftPickMutationResult = Apollo.MutationResult<UpdateMockDraftPickMutation>;
export type UpdateMockDraftPickMutationOptions = Apollo.BaseMutationOptions<UpdateMockDraftPickMutation, UpdateMockDraftPickMutationVariables>;
export const UpdateMockDraftStatusDocument = gql`
    mutation UpdateMockDraftStatus($key: String!, $status: String!) {
  updateMockDraftStatus(key: $key, status: $status) {
    status
    errors {
      key
      message
    }
  }
}
    `;
export type UpdateMockDraftStatusMutationFn = Apollo.MutationFunction<UpdateMockDraftStatusMutation, UpdateMockDraftStatusMutationVariables>;

/**
 * __useUpdateMockDraftStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMockDraftStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMockDraftStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMockDraftStatusMutation, { data, loading, error }] = useUpdateMockDraftStatusMutation({
 *   variables: {
 *      key: // value for 'key'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateMockDraftStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMockDraftStatusMutation, UpdateMockDraftStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMockDraftStatusMutation, UpdateMockDraftStatusMutationVariables>(UpdateMockDraftStatusDocument, options);
      }
export type UpdateMockDraftStatusMutationHookResult = ReturnType<typeof useUpdateMockDraftStatusMutation>;
export type UpdateMockDraftStatusMutationResult = Apollo.MutationResult<UpdateMockDraftStatusMutation>;
export type UpdateMockDraftStatusMutationOptions = Apollo.BaseMutationOptions<UpdateMockDraftStatusMutation, UpdateMockDraftStatusMutationVariables>;
export const ClientMockDraftDocument = gql`
    query ClientMockDraft($id: Int!) {
  clientMockDraft(id: $id) {
    autoLength
    clockLength
    clockMs
    draftPicks {
      id
      overall
      player {
        id
        img
        name
        position
        rookie
        team
      }
      round
      slot
      userId
      originalUserId
      realPick
    }
    id
    key
    players {
      id
      img
      name
      position
      rookie
      team
      order
      playerId
    }
    rookiesOnly
    status
    typeId
    users {
      auto
      id
      name
      players {
        id
        img
        name
        position
        rookie
        team
      }
      teamName
      online
      userId
    }
  }
}
    `;

/**
 * __useClientMockDraftQuery__
 *
 * To run a query within a React component, call `useClientMockDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientMockDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientMockDraftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientMockDraftQuery(baseOptions: Apollo.QueryHookOptions<ClientMockDraftQuery, ClientMockDraftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientMockDraftQuery, ClientMockDraftQueryVariables>(ClientMockDraftDocument, options);
      }
export function useClientMockDraftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientMockDraftQuery, ClientMockDraftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientMockDraftQuery, ClientMockDraftQueryVariables>(ClientMockDraftDocument, options);
        }
export type ClientMockDraftQueryHookResult = ReturnType<typeof useClientMockDraftQuery>;
export type ClientMockDraftLazyQueryHookResult = ReturnType<typeof useClientMockDraftLazyQuery>;
export type ClientMockDraftQueryResult = Apollo.QueryResult<ClientMockDraftQuery, ClientMockDraftQueryVariables>;
export const MockDraftTypesDocument = gql`
    query MockDraftTypes {
  mockDraftTypes {
    id
    name
    rankinTypeId
  }
}
    `;

/**
 * __useMockDraftTypesQuery__
 *
 * To run a query within a React component, call `useMockDraftTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMockDraftTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMockDraftTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMockDraftTypesQuery(baseOptions?: Apollo.QueryHookOptions<MockDraftTypesQuery, MockDraftTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MockDraftTypesQuery, MockDraftTypesQueryVariables>(MockDraftTypesDocument, options);
      }
export function useMockDraftTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MockDraftTypesQuery, MockDraftTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MockDraftTypesQuery, MockDraftTypesQueryVariables>(MockDraftTypesDocument, options);
        }
export type MockDraftTypesQueryHookResult = ReturnType<typeof useMockDraftTypesQuery>;
export type MockDraftTypesLazyQueryHookResult = ReturnType<typeof useMockDraftTypesLazyQuery>;
export type MockDraftTypesQueryResult = Apollo.QueryResult<MockDraftTypesQuery, MockDraftTypesQueryVariables>;
export const UserDraftsDocument = gql`
    query UserDrafts {
  userDrafts {
    id
    insertedAt
    key
    league
    leagueId
    position
    rookiesOnly
    rounds
    size
    status
    title
    type
    type
    typeId
  }
}
    `;

/**
 * __useUserDraftsQuery__
 *
 * To run a query within a React component, call `useUserDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDraftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserDraftsQuery(baseOptions?: Apollo.QueryHookOptions<UserDraftsQuery, UserDraftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDraftsQuery, UserDraftsQueryVariables>(UserDraftsDocument, options);
      }
export function useUserDraftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDraftsQuery, UserDraftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDraftsQuery, UserDraftsQueryVariables>(UserDraftsDocument, options);
        }
export type UserDraftsQueryHookResult = ReturnType<typeof useUserDraftsQuery>;
export type UserDraftsLazyQueryHookResult = ReturnType<typeof useUserDraftsLazyQuery>;
export type UserDraftsQueryResult = Apollo.QueryResult<UserDraftsQuery, UserDraftsQueryVariables>;
export const UserInfoDocument = gql`
    query UserInfo {
  userInfo {
    email
    id
    impersonator
    isAdmin
    isDynastygm
    isEditor
    isGm
    isNerd
    isPremium
    isRanker
    isTrial
    login
    name
    nicename
    status
    trialEnds
  }
}
    `;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
      }
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
        }
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
export const UserLeaguesDocument = gql`
    query UserLeagues {
  userLeagues {
    id
    name
    mockDraftTypeId
    teams {
      id
      name
      owned
    }
  }
}
    `;

/**
 * __useUserLeaguesQuery__
 *
 * To run a query within a React component, call `useUserLeaguesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLeaguesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLeaguesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLeaguesQuery(baseOptions?: Apollo.QueryHookOptions<UserLeaguesQuery, UserLeaguesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLeaguesQuery, UserLeaguesQueryVariables>(UserLeaguesDocument, options);
      }
export function useUserLeaguesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLeaguesQuery, UserLeaguesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLeaguesQuery, UserLeaguesQueryVariables>(UserLeaguesDocument, options);
        }
export type UserLeaguesQueryHookResult = ReturnType<typeof useUserLeaguesQuery>;
export type UserLeaguesLazyQueryHookResult = ReturnType<typeof useUserLeaguesLazyQuery>;
export type UserLeaguesQueryResult = Apollo.QueryResult<UserLeaguesQuery, UserLeaguesQueryVariables>;
export const EnterMockDraftDocument = gql`
    subscription EnterMockDraft($key: String!) {
  enterMockDraft(key: $key) {
    draftPicks {
      id
      overall
      player {
        id
      }
      round
      slot
      userId
    }
    id
    key
    status
    users {
      auto
      id
      name
      teamName
    }
  }
}
    `;

/**
 * __useEnterMockDraftSubscription__
 *
 * To run a query within a React component, call `useEnterMockDraftSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEnterMockDraftSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterMockDraftSubscription({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useEnterMockDraftSubscription(baseOptions: Apollo.SubscriptionHookOptions<EnterMockDraftSubscription, EnterMockDraftSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<EnterMockDraftSubscription, EnterMockDraftSubscriptionVariables>(EnterMockDraftDocument, options);
      }
export type EnterMockDraftSubscriptionHookResult = ReturnType<typeof useEnterMockDraftSubscription>;
export type EnterMockDraftSubscriptionResult = Apollo.SubscriptionResult<EnterMockDraftSubscription>;
import React from 'react';
import { Platform } from 'react-native';
import ReactScrollWheelHandler from 'react-scroll-wheel-handler';

interface Props {
  upHandler?: (event: any) => void;
  downHandler?: (event: any) => void;
  children: any;
}
export default function WebScrollHandler({
  upHandler,
  downHandler,
  children,
}: Props) {
  if (Platform.OS !== 'web') return children;
  return (
    <ReactScrollWheelHandler upHandler={upHandler} downHandler={downHandler}>
      {children}
    </ReactScrollWheelHandler>
  );
}

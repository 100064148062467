import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { tailwindCss } from '../../common/tailwind';

export interface INerdLoadingProps {}
export default function NerdLoading(props: INerdLoadingProps) {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <ActivityIndicator
        accessibilityHint="loading"
        size="large"
        color={(tailwindCss('text-red-500') as any).color}
      />
    </View>
  );
}

import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { tailwindCss } from '../../common/tailwind';
import { preModalStatus } from '../../contexts/NerdsApollo';
import useCache from '../../hooks/useCache';

export interface IDraftPlayToggleProps {
  draftId: number | undefined;
  status: string;
  toggle: () => void;
}
export default function DraftPlayToggle({
  draftId,
  toggle,
  status,
}: IDraftPlayToggleProps) {
  const PAUSED = 'Paused';

  const { handleUpdateStatus } = useCache();

  if (draftId === undefined) {
    return null;
  }

  const handlePress = () => {
    preModalStatus(status);
    handleUpdateStatus(draftId, PAUSED);
    toggle();
  };

  return (
    <View>
      <TouchableOpacity onPress={handlePress}>
        <FontAwesomeIcon
          icon={status === 'Paused' ? faPlay : faPause}
          size={20}
          style={tailwindCss('text-white m-3')}
        />
      </TouchableOpacity>
    </View>
  );
}

/* eslint-disable global-require */
import 'react-native-gesture-handler';
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useFonts } from 'expo-font';
import { useJwt } from './app/hooks/useJwt';
import MockNavigationView from './app/views/MockNavigationView';
import useReadyState from './app/hooks/useReadyState';
import NerdsApollo from './app/contexts/NerdsApollo';
import { tailwindCss } from './app/common/tailwind';
import NerdLoading from './app/components/atoms/NerdLoading';
import Login from './app/views/Login/Login';

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: (tailwindCss('bg-gray-200') as any).backgroundColor,
  },
};
const Stack = createStackNavigator();
export default function App() {
  const [jwt, setJwt] = useJwt();
  const [isReady, initialState, persistInitialState] = useReadyState();

  const [fontsLoaded] = useFonts({
    TitilliumWeb: require('./assets/fonts/TitilliumWeb-Regular.ttf'),
    TitilliumWebBold: require('./assets/fonts/TitilliumWeb-Bold.ttf'),
    TitilliumWebSemiBold: require('./assets/fonts/TitilliumWeb-SemiBold.ttf'),
    Prohibition: require('./assets/fonts/Prohibition-Regular.ttf'),
  });

  if (!isReady || !fontsLoaded) {
    return <NerdLoading />;
  }

  return (
    <NerdsApollo jwt={jwt}>
      <NavigationContainer
        theme={MyTheme}
        initialState={initialState as any}
        onStateChange={state => persistInitialState(state)}
      >
        <Stack.Navigator>
          {jwt ? (
            <>
              <Stack.Screen name="Mock">
                {props => <MockNavigationView {...props} setJwt={setJwt} />}
              </Stack.Screen>
            </>
          ) : (
            <>
              <Stack.Screen name="Sign In">
                {() => <Login setJwt={setJwt} />}
              </Stack.Screen>
            </>
          )}
        </Stack.Navigator>
      </NavigationContainer>
    </NerdsApollo>
  );
}

import React from 'react';
import { View, Text } from 'react-native';
import { UserLeague } from '../../generated/graphql';

export interface IPickLeagueProps {
  leagues: UserLeague[];
}
export default function PickLeague({ leagues }: IPickLeagueProps) {
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      <Text>PickLeague</Text>
    </View>
  );
}

export function formatDate(dateString: Date) {
  const date = new Date(dateString.replace(' ', 'T'));
  let hours = date.getHours();
  let minutes = date.getMinutes() as any;
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return `${date.getMonth() + 1
    }/${date.getDate()}/${date.getFullYear()}  ${strTime}`;
}

export function formatDateOnly(dateString: Date) {
  const date = new Date(dateString.replace(' ', 'T'));
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
}

import React from 'react';
import { Text } from 'react-native';

interface Props {
  children: any;
  style?: any;
}
export default function NerdText({ children, style = {} }: Props) {
  const myStyle =
    style.fontFamily === undefined
      ? { ...style, fontFamily: 'TitilliumWeb' }
      : style;
  return <Text style={myStyle}>{children}</Text>;
}

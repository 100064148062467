import { DraftPick } from '../generated/graphql';

export const getGroupedDraftPicks = (draftPicks: DraftPick[]) =>
  [...draftPicks]
    // .sort((a, b) => (a.overall > b.overall ? 1 : -1))
    .reduce(
      (
        acc: { title: string; data: DraftPick[]; round: number }[],
        curr: DraftPick,
      ) => {
        const group = acc.find(x => x.round === curr.round);
        if (group === undefined) {
          return [
            ...acc,
            { round: curr.round, title: `Round ${curr.round}`, data: [curr] },
          ];
        }

        return [
          ...acc.filter(x => x.round !== curr.round),
          { ...group, data: [...group.data, curr] },
        ];
      },
      [],
    );

import AsyncStorage from '@react-native-async-storage/async-storage';
import * as SecureStore from 'expo-secure-store';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { getBaseApi } from '../common/getBaseApi';

type hook = React.Dispatch<React.SetStateAction<string | undefined | null>>;
type booleanHook = React.Dispatch<React.SetStateAction<boolean>>;

const storeDelete = (key: string) => {
  if (Platform.OS === 'web') {
    return AsyncStorage.removeItem(key);
  }
  return SecureStore.deleteItemAsync(key);
};

const storeSet = (key: string, item: any) => {
  if (Platform.OS === 'web') {
    return AsyncStorage.setItem(key, item === null ? '' : JSON.stringify(item));
  }
  return SecureStore.setItemAsync(key, item);
};

const storeGet = async (key: string) => {
  if (Platform.OS === 'web') {
    const baseAPI = getBaseApi();
    const inLocal = await AsyncStorage.getItem(key);
    if (inLocal === null) {
      const response = await window.fetch(`${baseAPI}/jwt`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'content-type': 'application/json;charset=UTF-8',
        },
      });
      const authInfo = await response.json();
      if (authInfo.status === 'ok' && authInfo.jwt) {
        storeSet('jwt', authInfo.jwt);
      }
    }

    return AsyncStorage.getItem(key).then(item => {
      return item === null ? null : JSON.parse(item);
    });
  }
  return SecureStore.getItemAsync(key);
};
export const useJwt = (): [
  string | undefined | null,
  hook,
  boolean,
  booleanHook,
] => {
  const [jwt, setJwt] = useState<string | null>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    if (jwt === null) {
      storeDelete('jwt');
    }
    if (jwt !== undefined && jwt !== null) {
      storeSet('jwt', jwt);
    }
  }, [jwt]);

  useEffect(() => {
    storeGet('jwt').then(storedJwt => {
      if (storedJwt) {
        setJwt(storedJwt);
      }
      setLoading(false);
    });
  }, []);
  return [jwt, setJwt, loading, setLoading];
};

import React from 'react';
import { Text, TouchableOpacity, GestureResponderEvent } from 'react-native';
import { tailwindCss } from '../common/tailwind';

export interface IbuttonProps {
  onPress: (event: GestureResponderEvent) => void;
  color?: string;
  textColor?: string;
  children: JSX.Element | JSX.Element[] | string;
  width?: string;
  additional?: any;
  padding?: string;
  shadow?: string;
  paddingX?: string;
  rnStyle?: any;
}

export default function NerdButton({
  children,
  onPress,
  color,
  textColor,
  width,
  additional,
  padding,
  shadow,
  paddingX,
  rnStyle,
}: IbuttonProps) {
  const myColor = color || 'bg-red-500';
  const myTextColor = textColor || 'text-white';
  const myWidth = width === undefined ? 'w-full' : width;
  const myAdditional = additional || '';
  const myPadding = padding || 'py-4';
  const px = paddingX || 'px-5';
  const myShadow = shadow || 'shadow';
  const myRnStyle = rnStyle || {};
  return (
    <TouchableOpacity
      style={{
        ...tailwindCss(
          `${myColor} ${myPadding} ${px} rounded-full ${myWidth} items-center flex ${myAdditional}`,
        ),
        ...myRnStyle,
      }}
      onPress={onPress}
    >
      <Text
        style={{
          ...tailwindCss(`${myTextColor} font-semibold `),
          fontFamily: 'TitilliumWebSemiBold',
          textAlign: 'center',
          minWidth: 13,
        }}
      >
        {children}
      </Text>
    </TouchableOpacity>
  );
}

import { useNavigation } from '@react-navigation/native';
import React, { useReducer } from 'react';
import { ActivityIndicator, Text, TouchableOpacity, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { getMock } from '../common/getNewMock';
import { tailwindCss } from '../common/tailwind';
import {
  MockDraftType,
  useNewClientMockDraftMutation,
  UserLeague,
} from '../generated/graphql';
import NerdText from './atoms/NerdText';
import PillRadio from './atoms/PillRadio';
import { Actions, getInit, Option, stateReducer } from './MockDraftState';
import NerdButton from './NerdButton';

export interface ICreateMockDraftProps {
  mockDraftTypes: MockDraftType[];
  leagues: UserLeague[];
}

export default function CreateMockDraft({
  mockDraftTypes,
  leagues,
}: ICreateMockDraftProps) {
  const initState = getInit(mockDraftTypes, leagues);
  const textStyle = {
    ...tailwindCss('text-gray-600 mt-2'),
    fontFamily: 'TitilliumWeb',
  };
  const textStyleNoBold = tailwindCss('text-lg text-gray-700 m-2 p-4 ');

  const [state, dispatch] = useReducer(stateReducer, initState);

  const {
    autoClock,
    userClock,
    type,
    size,
    round,
    slot,
    mockType,
    orderType,
    userLeague,
    showLeagues,
    showScoring,
    showTeams,
    showRounds,
    showSlot,
    showOrder,
    mockTypes,
    scoringTypes,
    orderTypes,
    rounds,
    sizes,
    slots,
  } = state;

  const [
    newClientMockDraftMutation,
    { loading },
  ] = useNewClientMockDraftMutation({
    variables: {
      newMockDraft: getMock(
        slot.id,
        round.id,
        size.id,
        type.id,
        autoClock.id,
        userClock.id,
        mockType.name === 'Rookie',
        userLeague,
        orderType.name,
      ),
    },
  });

  const navigation = useNavigation();
  const createMock = () => {
    newClientMockDraftMutation().then(x => {
      navigation.navigate('Mock', {
        id: x.data?.newClientMockDraft.id,
      });
    });
  };

  if (userLeague === undefined && leagues.length > 0) {
    return (
      <ScrollView>
        <View style={tailwindCss('m-10')}>
          <NerdText style={tailwindCss('text-lg')}>
            Which of your leagues would you like to mock-draft with?
          </NerdText>
        </View>
        {leagues.map(league => (
          <View key={league.id} style={tailwindCss('m-1')}>
            <NerdButton
              onPress={() => dispatch({ action: 'League', value: league })}
            >
              {league.name}
            </NerdButton>
          </View>
        ))}
        <View style={tailwindCss('m-1')}>
          <NerdButton
            onPress={() => dispatch({ action: Actions.League, value: null })}
          >
            Mock Without League
          </NerdButton>
        </View>
      </ScrollView>
    );
  }

  return (
    <ScrollView>
      <View style={tailwindCss('px-3')}>
        <Text style={textStyle}>Type</Text>
      </View>

      <PillRadio<Option>
        options={mockTypes}
        value={mockType}
        setValue={value => dispatch({ action: Actions.MockType, value })}
      />

      {showLeagues && (
        <>
          <View style={tailwindCss('px-3')}>
            <Text style={textStyle}>Your League</Text>
          </View>
          <View style={tailwindCss('flex-row items-center')}>
            <TouchableOpacity
              onPress={() =>
                dispatch({ action: Actions.League, value: undefined })
              }
            >
              <Text style={textStyleNoBold}>
                {userLeague?.name || 'Not using league'}
              </Text>
            </TouchableOpacity>
            <NerdButton
              width=""
              onPress={() =>
                dispatch({ action: Actions.League, value: undefined })
              }
              paddingX="px-4"
              padding="py-3"
            >
              Change
            </NerdButton>
          </View>
        </>
      )}

      {showScoring && (
        <>
          <View style={tailwindCss('px-3')}>
            <Text style={textStyle}>Scoring</Text>
          </View>
          <PillRadio<MockDraftType>
            options={scoringTypes}
            value={type}
            setValue={value => dispatch({ action: Actions.Type, value })}
          />
        </>
      )}

      {showTeams && (
        <>
          <View style={tailwindCss('px-3')}>
            <Text style={textStyle}>Teams</Text>
          </View>
          <PillRadio<Option>
            options={sizes}
            value={size}
            setValue={value => dispatch({ action: Actions.Size, value })}
          />
        </>
      )}

      <>
        {showRounds && (
          <>
            <View style={tailwindCss('px-3')}>
              <Text style={textStyle}>Rounds</Text>
            </View>
            <PillRadio<Option>
              options={rounds}
              value={round}
              setValue={value => dispatch({ action: Actions.Round, value })}
            />
          </>
        )}
        {showSlot && (
          <>
            <View style={tailwindCss('px-3')}>
              <Text style={textStyle}>Position</Text>
            </View>
            <PillRadio<Option>
              options={slots}
              value={slot}
              setValue={value => dispatch({ action: Actions.Slot, value })}
            />
          </>
        )}

        {showOrder && (
          <>
            <View style={tailwindCss('px-3')}>
              <Text style={textStyle}>Order</Text>
            </View>
            <PillRadio<Option>
              options={orderTypes}
              value={orderType}
              setValue={value => dispatch({ action: Actions.OrderType, value })}
            />
          </>
        )}
      </>

      <View style={tailwindCss('my-3 mx-3 mt-8')}>
        <NerdButton onPress={createMock}>
          {loading ? (
            <ActivityIndicator size="small" color="white" />
          ) : (
            'Create'
          )}
        </NerdButton>
      </View>
    </ScrollView>
  );
}

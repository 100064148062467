import BottomSheet from '@gorhom/bottom-sheet';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Animated,
  Linking,
  Platform,
  useWindowDimensions,
  View,
} from 'react-native';
import { getBaseApi } from '../../common/getBaseApi';
import { tailwindCss } from '../../common/tailwind';
import NerdLoading from '../../components/atoms/NerdLoading';
import NerdText from '../../components/atoms/NerdText';
import JoinMock from '../../components/JoinMock';
import NerdButton from '../../components/NerdButton';
import SheetPlayers from '../../components/SheetPlayers';
import {
  Draft,
  Player,
  useClientMockDraftQuery,
  User,
  UserInfoQuery,
} from '../../generated/graphql';
import useCache from '../../hooks/useCache';
import ChangeClaimUser from './ChangeClaimUser';
import PickLeague from './PickLeague';

export interface IJoinMockDataViewProps {
  route: any;
  setTitle: (title: string) => void;
  setId: (id: number) => void;
  setStatus: (status: string) => void;
  userData: UserInfoQuery;
  showPauseToggle: (show: boolean) => void;
  showDraftMenu: boolean;
  setShowDraftMenu: (show: boolean) => void;
}

export default function JoinMockDataView({
  route,
  setTitle,
  setId,
  setStatus,
  userData,
  showPauseToggle,
  showDraftMenu,
  setShowDraftMenu,
}: IJoinMockDataViewProps) {
  const baseAPI = getBaseApi();
  const { id } = route.params;
  useEffect(() => {
    setId(id);
  }, [id, setId]);
  const { data, loading } = useClientMockDraftQuery({
    variables: { id },
  });

  const mock = data?.clientMockDraft;
  const {
    handleAutoPick,
    handleClaimUser,
    handleUpdateStatus,
    handleMakePick,
  } = useCache();

  const forced = 'true';
  const autoPick = (draftPickId: number | undefined) => {
    if (mock && draftPickId) {
      handleAutoPick(mock.id, draftPickId);
    }
  };

  const pauseClock = () => {
    if (mock) {
      handleUpdateStatus(mock.id, 'Paused');
    }
  };

  const sheetRef = React.useRef<BottomSheet>(null);

  const draftPicks = mock?.draftPicks || [];
  const onClock = draftPicks.find(x => x.player === null);
  const players2 = mock?.draftPicks
    .filter(p => p.player !== null)
    .map(p => p.player?.id);

  const availablePlayers =
    mock?.players.filter(p => !players2?.includes(p.id)) || [];

  const getCountDownLength = () => {
    if (userData?.userInfo?.id === onClock?.userId) {
      return mock?.clockLength || 0;
    }
    return mock?.autoLength || 0;
  };
  const countDownLength = getCountDownLength();

  const players = mock?.players || [];

  const users = mock?.users || [];
  const [claimUser, setClaimUser] = useState<User>();
  const [rosterUser, setRosterUser] = useState<User>();
  const [sortOrder, setSortOrder] = useState('DraftOrder');
  const userId = claimUser?.userId || userData?.userInfo?.id || 0;
  useEffect(() => {
    const mockUser = mock?.users?.find(x => x.userId === userId);
    if (mockUser) {
      setClaimUser(mockUser as User);
      setRosterUser(mockUser as User);
    }
  }, [mock, userId]);

  const Stack = createStackNavigator();
  const Tab = createBottomTabNavigator();
  const points = useMemo(() => [0.2, 0.4, 0.6, 0.8, 1], []);
  const snapPoints = useMemo(() => points.map(p => `${p * 100}%`), [points]);
  const [bottomIndex, setBottomIndex] = useState<number>(1);

  const handleBottomSheetChange = (index: number) => {
    setBottomIndex(index);
  };
  const status = mock?.status;
  useEffect(() => {
    setStatus(status || '');
  }, [status, setStatus]);

  const windowHeight = useWindowDimensions().height;

  const adjustedHeight =
    bottomIndex <= 1 ? points[bottomIndex] + 0.05 : points[bottomIndex];

  const footerHeight =
    Platform.OS !== 'web' ? windowHeight * adjustedHeight : 0;

  const [webHeightIndex, setWebHeightIndex] = useState(1);

  const onPressUp = () => {
    if (webHeightIndex < points.length) {
      setWebHeightIndex(webHeightIndex + 1);
    }
  };
  const onPressDown = () => {
    if (webHeightIndex > 0) {
      setWebHeightIndex(webHeightIndex - 1);
    }
  };
  const webHeight =
    Platform.OS === 'web' ? windowHeight * points[webHeightIndex] : 0;
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (Platform.OS === 'web') {
      Animated.timing(fadeAnim, {
        toValue: Platform.OS === 'web' ? webHeight : 0,
        duration: 250,
        useNativeDriver: false,
      }).start();
    }
  }, [fadeAnim, webHeight]);

  const positionMap = {
    QB: 1,
    RB: 2,
    WR: 3,
    TE: 4,
  };

  const sortPlayers = (a: Player, b: Player) => {
    const b1 = a.order;
    const b2 = b.order;

    if (sortOrder === 'Position') {
      const a1 = (positionMap as any)[a.position];
      const a2 = (positionMap as any)[b.position];

      if (a1 < a2) return -1;
      if (a1 > a2) return 1;
    }

    if (b1 < b2) return -1;
    if (b1 > b2) return 1;

    return 0;
  };
  const roster = ((mock?.draftPicks
    .filter(dp => dp.player !== null && dp.userId === rosterUser?.userId)
    .map(dp => {
      const player = players?.find(p => p.id === dp.player?.id);
      return { ...player, team: `${dp.round}.${dp.slot}` };
    }) || []) as Player[]).sort(sortPlayers);

  const canPick = onClock?.userId === userId;

  const resetClaimUser = () => {
    const mockUser = mock?.users?.find(x => x.userId === userData.userInfo?.id);
    if (mockUser) {
      setClaimUser(mockUser as User);
    }
  };

  const navigation = useNavigation();
  const handleCreateMock = () => {
    navigation.navigate('Create Mock');
  };

  if (mock === undefined && loading === false) {
    return (
      <View style={tailwindCss('items-center flex justify-center h-full')}>
        <View style={tailwindCss('p-3 w-full')}>
          <NerdButton onPress={handleCreateMock}>Create Mock</NerdButton>
        </View>
      </View>
    );
  }

  if (loading) return <NerdLoading />;

  const renderJoinMock = () => {
    if (!mock || !claimUser) {
      return <NerdLoading />;
    }
    return (
      <>
        <JoinMock
          showDraftMenu={showDraftMenu}
          setShowDraftMenu={setShowDraftMenu}
          handleClaimUser={handleClaimUser}
          showPauseToggle={showPauseToggle}
          setTitle={setTitle}
          userInfo={userData?.userInfo as any}
          mockDraft={mock as Draft}
          startClock={() => {
            handleUpdateStatus(mock.id, 'Running');
          }}
          onClock={onClock as any}
          autoPick={autoPick}
          pauseClock={pauseClock}
          countDownLegnth={countDownLength}
          players={players}
          claimUser={claimUser}
          footerHeight={footerHeight}
          resetClaimUser={resetClaimUser}
        />

        {status !== undefined && status !== 'New' && Platform.OS === 'web' && (
          <Animated.View
            style={{
              ...tailwindCss('shadow-tl pt-4 bg-white flex flex-row'),
              height: fadeAnim,
            }}
          >
            <SheetPlayers
              makePick={(pickId: number) =>
                canPick &&
                handleMakePick(
                  mock.id,
                  onClock?.id || 0,
                  pickId,
                  status !== 'Running',
                )
              }
              status={status}
              roster={roster}
              upDownControls
              onPressUp={onPressUp}
              onPressDown={onPressDown}
              players={availablePlayers as Player[]}
              rosterUser={rosterUser as User}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
            />
          </Animated.View>
        )}
        {status !== undefined && status !== 'New' && Platform.OS !== 'web' && (
          <BottomSheet
            style={tailwindCss('shadow-tl')}
            ref={sheetRef}
            index={bottomIndex}
            onChange={handleBottomSheetChange}
            snapPoints={snapPoints}
          >
            <SheetPlayers
              makePick={(pickId: number) =>
                canPick &&
                handleMakePick(
                  mock.id,
                  onClock?.id || 0,
                  pickId,

                  status !== 'Running',
                )
              }
              status={status}
              roster={roster}
              upDownControls={false}
              players={availablePlayers as Player[]}
              rosterUser={rosterUser as User}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
            />
          </BottomSheet>
        )}
      </>
    );
  };

  const renderJoinMockWithNav = () => {
    return (
      <Tab.Navigator
        initialRouteName="Draft Complete"
        tabBarOptions={{
          labelStyle: {
            fontFamily: 'TitilliumWebBold',
            fontSize: 14,
          },
        }}
      >
        <Tab.Screen
          name="Start New"
          listeners={({ navigation }) => ({
            tabPress: e => {
              e.preventDefault();
              navigation.navigate('Create Mock');
            },
          })}
        >
          {() => renderJoinMock()}
        </Tab.Screen>
        <Tab.Screen name="Draft Complete">{() => renderJoinMock()}</Tab.Screen>
        <Tab.Screen
          name="Share"
          listeners={() => ({
            tabPress: e => {
              e.preventDefault();
              if (mock?.key) {
                Linking.openURL(`${baseAPI}/link/share-mock/${mock?.key}`);
              }
            },
          })}
        >
          {() => renderJoinMock()}
        </Tab.Screen>
      </Tab.Navigator>
    );
  };

  if (mock && claimUser)
    return (
      <Stack.Navigator headerMode="none">
        <Stack.Screen name="Draft">
          {status !== 'Complete' ? renderJoinMock : renderJoinMockWithNav}
        </Stack.Screen>
        <Stack.Screen name="Pick League">
          {props => <PickLeague {...props} leagues={[]} />}
        </Stack.Screen>
        <Stack.Screen name="Change Claim">
          {props => (
            <ChangeClaimUser
              {...props}
              users={users as User[]}
              currentUser={claimUser}
              setCurrentUser={setClaimUser}
              displayText="Which Team would you like to claim picks for?"
            />
          )}
        </Stack.Screen>
        <Stack.Screen name="Change Roster">
          {props => (
            <ChangeClaimUser
              {...props}
              users={users as User[]}
              currentUser={rosterUser}
              displayText="Which Team's roster would you like to view?"
              setCurrentUser={setRosterUser}
            />
          )}
        </Stack.Screen>
      </Stack.Navigator>
    );
  return <NerdLoading />;
}

import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { ScrollView, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { formatDateOnly } from '../common/formatDate';
import { tailwindCss } from '../common/tailwind';
import { UserDraft } from '../generated/graphql';
import NerdText from './atoms/NerdText';
import NerdButton from './NerdButton';

export interface IMockListProps {
  list: Array<UserDraft>;
}

export default function MockList({ list }: IMockListProps) {
  const cellStyle = (index: number, width: string) =>
    index % 2 === 1
      ? tailwindCss(`bg-white px-4 ${width} text-gray-700 `)
      : tailwindCss(`bg-gray-200 px-4 ${width}  text-gray-700`);

  const navigation = useNavigation();

  const handleOnPress = (mock: UserDraft) => {
    navigation.navigate('Mock', { id: mock.id });
  };

  const handleCreateMock = () => {
    navigation.navigate('Create Mock');
  };

  function getDisplayName(mock: UserDraft) {
    if (mock.league) {
      return `${mock.league} - ${mock.rookiesOnly ? 'Rookie' : 'Start-Up'}`;
    }
    return `${mock.type}  - ${mock.size} Team - ${
      mock.rookiesOnly ? 'Rookie' : 'Start-Up'
    }`;
  }
  if (list.length === 0)
    return (
      <View style={tailwindCss('items-center flex justify-center h-full')}>
        <View style={tailwindCss('p-3 w-full')}>
          <NerdButton onPress={handleCreateMock}>Create Mock</NerdButton>
        </View>
      </View>
    );

  return (
    <ScrollView>
      {list.map((mock, index) => (
        <TouchableOpacity
          key={mock.id}
          style={tailwindCss('flex shadow-b')}
          onPress={() => handleOnPress(mock)}
        >
          <View style={tailwindCss('flex-row')}>
            <NerdText
              style={{
                ...cellStyle(index, 'text-lg pt-2'),
                fontFamily: 'Prohibition',
                width: '100%',
              }}
            >
              {getDisplayName(mock)}
            </NerdText>
          </View>

          <View style={tailwindCss('flex-row')}>
            <NerdText
              style={{
                ...cellStyle(index, 'w-1/4 pb-2 text-xs'),
              }}
            >
              {mock.status}
            </NerdText>
            <NerdText
              style={{
                ...cellStyle(index, 'w-2/4 pb-2 text-xs'),
              }}
            >
              {formatDateOnly(mock.insertedAt)}
            </NerdText>
            <NerdText
              style={{
                ...cellStyle(index, 'w-1/4 pb-2 text-xs'),
              }}
            >
              {mock.rounds} Round
            </NerdText>
          </View>
        </TouchableOpacity>
      ))}
    </ScrollView>
  );
}

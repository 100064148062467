import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import React, { useCallback } from 'react';
import { View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { tailwindCss } from '../../common/tailwind';

export interface IHamburgerProps {}
export default function Hamburger(props: IHamburgerProps) {
  const navigation = useNavigation();

  const openDrawer = useCallback(() => {
    navigation.dispatch(DrawerActions.toggleDrawer());
    // navigation.dispatch(DrawerActions.openDrawer());
  }, [navigation]);
  return (
    <View>
      <TouchableOpacity onPress={openDrawer}>
        <FontAwesomeIcon
          icon={faBars}
          size={18}
          style={tailwindCss('text-white m-3')}
        />
      </TouchableOpacity>
    </View>
  );
}

import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import NerdLoading from '../../components/atoms/NerdLoading';
import MockList from '../../components/MockList';
import { useUserDraftsQuery } from '../../generated/graphql';
import { HeaderProps } from '../MockNavigationView';

export interface IMockListViewProps {
  setTitle: (title: string) => void;
}

export default function MockListDataView({ setTitle }: IMockListViewProps) {
  const { data, refetch } = useUserDraftsQuery({ fetchPolicy: 'no-cache' });

  useFocusEffect(
    React.useCallback(() => {
      refetch();
      setTitle('Your Mocks');
      return () => {
        setTitle('');
      };
    }, [setTitle]),
  );

  if (data?.userDrafts) return <MockList list={data.userDrafts as any} />;
  return <NerdLoading />;
}

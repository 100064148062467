import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { tailwindCss } from '../../common/tailwind';
import NerdText from '../../components/atoms/NerdText';
import NerdButton from '../../components/NerdButton';
import { User } from '../../generated/graphql';

export interface IChangeClaimUserProps {
  users: User[];
  currentUser: User | undefined;
  setCurrentUser: any;
  displayText: string;
}

export const getName = (user: User) => {
  return user.teamName;
};

export default function ChangeClaimUser({
  users,
  setCurrentUser,
  displayText,
}: IChangeClaimUserProps) {
  const navigation = useNavigation();

  const handlePress = (user: User) => {
    setCurrentUser(user);
    navigation.navigate('Draft');
  };

  return (
    <ScrollView style={tailwindCss('m-3 mt-10')}>
      <NerdText>{displayText}</NerdText>
      {users.map(user => (
        <NerdButton
          key={user.id}
          additional="my-2"
          onPress={() => {
            handlePress(user);
          }}
        >
          <NerdText>{getName(user)}</NerdText>
        </NerdButton>
      ))}
    </ScrollView>
  );
}

import {
  faEye,
  faEyeSlash,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import {
  ActivityIndicator,
  Linking,
  Text,
  TextInput,
  View,
} from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { getBaseApi } from '../../common/getBaseApi';
import { LoginState } from '../../common/loginState';
import { tailwindCss } from '../../common/tailwind';
import NerdText from '../../components/atoms/NerdText';
import NerdButton from '../../components/NerdButton';
import { login } from '../../services/login';

interface Props {
  email: string;
  setLoginState: (state: LoginState) => void;
  setJwt: (jwt: any) => void;
  setEmail: (email: string) => void;
}
export default function EnterPassword({
  email,
  setEmail,
  setLoginState,
  setJwt,
}: Props) {
  const [message, setMessage] = useState<string>(
    'Looks like you already have a dynastynerds.com account! Please enter your password to sign in.',
  );
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const baseAPI = getBaseApi();
  const navigation = useNavigation();
  const [showPassword, setShowPassword] = useState(false);
  const onPress = () => {
    setLoading(true);

    login({ username: email, password }).then(jwt => {
      if (jwt !== 'error') {
        setMessage('');
        setJwt(jwt);
        setLoading(false);
        navigation.navigate('Mock');
      } else {
        setMessage('The email or password you entered is incorrect.');
        setLoading(false);
      }
    });
  };
  return (
    <>
      <View style={tailwindCss('flex-row items-center mt-4 w-full')}>
        <View
          style={tailwindCss(
            'bg-white p-3 border border-gray-300 rounded flex-1',
          )}
        >
          <NerdText>{email}</NerdText>
        </View>
        <Text
          style={tailwindCss('mx-4 items-center flex-row')}
          onPress={() => {
            setEmail('');
            setLoginState(LoginState.Email);
          }}
        >
          <FontAwesomeIcon
            style={tailwindCss('text-gray-700')}
            icon={faTimesCircle}
          />
        </Text>
      </View>

      <View style={tailwindCss('flex-row items-center mt-4 w-full')}>
        <View
          style={tailwindCss(
            'bg-white p-3 border border-gray-300 rounded flex-1',
          )}
        >
          <TextInput
            focusable
            placeholder="Password"
            onChangeText={setPassword}
            value={password}
            autoCompleteType="password"
            textContentType={showPassword ? 'none' : 'password'}
            secureTextEntry={!showPassword}
          />
        </View>
        <Text
          style={tailwindCss('mx-4')}
          onPress={() => {
            setShowPassword(!showPassword);
          }}
        >
          <FontAwesomeIcon
            style={tailwindCss('text-gray-700')}
            icon={showPassword ? faEyeSlash : faEye}
          />
        </Text>
      </View>

      <View style={{ ...tailwindCss('p-2'), minHeight: 30 }}>
        <NerdText>{message}</NerdText>
      </View>

      <NerdButton onPress={onPress} additional="mt-3">
        {loading ? (
          <ActivityIndicator size="small" color="white" />
        ) : (
          <NerdText>Sign In</NerdText>
        )}
      </NerdButton>

      <View style={tailwindCss('p-2 mt-6')}>
        <TouchableOpacity
          onPress={() => {
            Linking.openURL(`${baseAPI}/link/forgot-password`);
          }}
        >
          <NerdText>Forgot Password</NerdText>
        </TouchableOpacity>
      </View>
    </>
  );
}

import { useFocusEffect } from '@react-navigation/native';
import React from 'react';
import { getsImport } from '../../common/userFeatures';
import NerdLoading from '../../components/atoms/NerdLoading';
import CreateMockDraft from '../../components/CreateMockDraft';
import {
  useMockDraftTypesQuery,
  UserInfoQuery,
  useUserLeaguesLazyQuery,
} from '../../generated/graphql';

export interface ICreateMockDataViewProps {
  setTitle: (title: string) => void;
  userData: UserInfoQuery;
}

export default function CreateMockDataView({
  setTitle,
  userData,
}: ICreateMockDataViewProps) {
  useFocusEffect(
    React.useCallback(() => {
      setTitle('Create Mock');
      return () => {
        setTitle('');
      };
    }, [setTitle]),
  );

  const { data } = useMockDraftTypesQuery();
  const [
    getUserLeagues,
    { loading, data: userLeaguesData },
  ] = useUserLeaguesLazyQuery();

  const useLeagues = getsImport(userData);
  if (useLeagues && !loading && userLeaguesData === undefined) {
    getUserLeagues();
  }
  const userLeagues = useLeagues ? userLeaguesData?.userLeagues || [] : [];

  if (data?.mockDraftTypes && !loading)
    return (
      <CreateMockDraft
        leagues={userLeagues}
        mockDraftTypes={[...data.mockDraftTypes]}
      />
    );

  return <NerdLoading />;
}

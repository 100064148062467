import { createDrawerNavigator } from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { Image, Platform, StyleSheet, View } from 'react-native';
import { tailwindCss } from '../common/tailwind';
import DraftPlayToggle from '../components/molecules/DraftPlayToggle';
import Hamburger from '../components/molecules/Hamburger';
import { useUserInfoQuery } from '../generated/graphql';
import CreateMockDataView from './MockNavigationView/CreateMockDataView';
import JoinMockDataView from './MockNavigationView/JoinMockDataView';
import MockListDataView from './MockNavigationView/MockListDataView';
import UserInfoDataView from './MockNavigationView/UserInfoDataView';

export interface IMockProps {
  setJwt: (jwt: string | null) => void;

  route: any;
}

export type HeaderProps = {
  title: string;
  status: string;
  draftActive: boolean;
};
const Drawer = createDrawerNavigator();
export default function MockNavigationView({ setJwt, route }: IMockProps) {
  const navigation = useNavigation();

  const [navTitle, setNavTitle] = useState('');
  const [showPauseToggle, setShowPauseToggle] = useState(false);
  const [id, setId] = useState<number>();
  const [status, setStatus] = useState('');

  const [showDraftMenu, setShowDraftMenu] = useState(false);
  const toggleDraftmenu = useCallback(() => {
    setShowDraftMenu(!showDraftMenu);
  }, [showDraftMenu]);

  useEffect(() => {
    const size = 50;
    const paddingTop = Platform.OS === 'web' ? 10 : 40;
    const styles = StyleSheet.create({
      image: {
        width: size,
        height: size,
      },
      container: {
        paddingBottom: 5,
        paddingTop,
        backgroundColor: '#990f00',
        alignItems: 'center',
        justifyContent: 'flex-end',
        fontFamily: 'Prohibition',
      },
    });
    const headerBackground =
      navTitle === ''
        ? () => (
            <View style={styles.container}>
              <Image
                style={styles.image}
                source={require('../../assets/icon.png')}
              />
            </View>
          )
        : null;
    navigation.setOptions({
      headerLeft: () => <Hamburger />,
      headerRight: () =>
        showPauseToggle ? (
          <DraftPlayToggle
            draftId={id}
            status={status}
            toggle={toggleDraftmenu}
          />
        ) : null,
      headerStyle: tailwindCss('bg-red-500 '),
      headerTitleStyle: {
        ...tailwindCss('text-white'),
        fontFamily: 'Prohibition',
      },
      title: navTitle,
      headerBackground,
    });
  }, [showPauseToggle, navTitle, id, navigation, status, toggleDraftmenu]);

  const { data: userData, loading } = useUserInfoQuery();
  if (loading || userData === undefined) {
    return null;
  }

  return (
    <Drawer.Navigator
      drawerType="slide"
      initialRouteName="Your Mocks"
      drawerContentOptions={{
        activeTintColor: 'rgba(153, 15, 0, 1)',
        labelStyle: {
          ...{
            fontFamily: 'TitilliumWebBold',
            fontSize: 14,
          },
          ...tailwindCss('text-red-500'),
        },
      }}
    >
      <Drawer.Screen name="Create Mock">
        {props => (
          <CreateMockDataView
            {...props}
            setTitle={setNavTitle}
            userData={userData}
          />
        )}
      </Drawer.Screen>

      <Drawer.Screen name="Mock" initialParams={{ mock: { key: '' } }}>
        {props => (
          <JoinMockDataView
            {...props}
            showDraftMenu={showDraftMenu}
            setShowDraftMenu={setShowDraftMenu}
            showPauseToggle={setShowPauseToggle}
            setTitle={setNavTitle}
            setId={setId}
            setStatus={setStatus}
            userData={userData}
          />
        )}
      </Drawer.Screen>

      <Drawer.Screen name="Your Mocks">
        {props => <MockListDataView {...props} setTitle={setNavTitle} />}
      </Drawer.Screen>

      <Drawer.Screen name="User Info">
        {props => (
          <UserInfoDataView setJwt={setJwt} {...props} setTitle={setNavTitle} />
        )}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
}

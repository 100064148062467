import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faEye,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useNavigation } from '@react-navigation/native';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  GestureResponderEvent,
  Platform,
  Modal,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import ModalWeb from 'modal-enhanced-react-native-web';
import { tailwindCss } from '../common/tailwind';
import { Player, User } from '../generated/graphql';
import PositionToggler, {
  getPositionTextColor,
} from './molecules/PositionToggler';
import NerdButton from './NerdButton';
import { styles } from './molecules/DraftControlModal';
import NerdText from './atoms/NerdText';
import WebScrollHandler from './molecules/WebScrollHandler';

export interface ISheetPlayersProps {
  players: Player[];
  roster: Player[];
  upDownControls: boolean;
  onPressUp?: (event: GestureResponderEvent) => void;
  onPressDown?: (event: GestureResponderEvent) => void;
  status: string;
  makePick: (playerId: number) => void;
  rosterUser: User;
  sortOrder: string;
  setSortOrder: (order: string) => void;
}

export default function SheetPlayers({
  players,
  roster,
  upDownControls,
  onPressUp,
  onPressDown,
  status,
  makePick,
  rosterUser,
  sortOrder,
  setSortOrder,
}: ISheetPlayersProps) {
  const [viewing, setViewing] = useState('Players');

  const navigation = useNavigation();
  const cellStyle = (index: number) => {
    return {
      ...tailwindCss(`p-3 w-1/2 font-bold text-lg text-gray-700`),
      fontFamily: 'TitilliumWebSemiBold',
    };
  };
  const cellStyle2 = (index: number) => {
    return {
      ...tailwindCss(`p-3 w-1/6 text-gray-700`),
      fontFamily: 'TitilliumWeb',
    };
  };

  const cellStyle3 = (index: number) => {
    return {
      ...tailwindCss(`p-3 w-1/6 text-gray-700`),
    };
  };

  const attemptPick = (player: Player) => makePick(player.id);

  useEffect(() => {
    if (status === 'Complete') {
      setViewing('Rosters');
    }
  }, [status]);

  const renderPlayerItem = ({
    item: p,
    index,
  }: {
    item: Player;
    index: number;
  }) => {
    return (
      <View
        key={p.id}
        style={tailwindCss(
          `${
            index % 2 === 0 ? 'bg-gray-200' : 'bg-white'
          } flex-row items-center`,
        )}
      >
        <Text style={cellStyle(index)}>{p.name}</Text>
        <Text
          style={{
            ...cellStyle2(index),
            ...tailwindCss(getPositionTextColor(p.position)),
          }}
        >
          {p.position}
        </Text>
        <Text style={cellStyle2(index)}>{p.team}</Text>

        {viewing === 'Players' && (
          <TouchableOpacity
            style={cellStyle3(index)}
            onPress={() => {
              attemptPick(p);
            }}
          >
            <FontAwesomeIcon
              icon={faPlus}
              style={tailwindCss('text-gray-700 ')}
            />
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const [filter, setFilter] = useState<{
    postition: string[];
    term: string;
    search: boolean;
  }>({
    search: false,
    postition: ['QB', 'RB', 'WR', 'TE'],
    term: '',
  });

  const getFilteredPlayers = () => {
    if (
      (filter.postition.length === 0 || filter.postition.length === 4) &&
      filter.search === false
    ) {
      return players;
    }

    if (filter.search) {
      return matchSorter(players, filter.term, { keys: ['name'] });
    }

    return players.filter(player =>
      filter.postition.includes(player?.position),
    );
  };

  const filteredPlayers = getFilteredPlayers();

  const getViewingStyle = (type: string) => {
    return type === viewing
      ? tailwindCss('text-xl text-red-500')
      : tailwindCss('text-xl text-gray-800');
  };

  const [showModal, setShowModal] = useState(false);

  const view = (
    <View style={styles.centeredView}>
      <View style={styles.modalView}>
        <Text>Sort By</Text>
        <TouchableOpacity
          style={[styles.button, styles.redButton]}
          onPress={() => {
            setSortOrder('Draft Order');
            setShowModal(false);
          }}
        >
          <Text style={styles.textStyle}>Draft Order</Text>
        </TouchableOpacity>

        <TouchableOpacity style={[styles.button, styles.redButton]}>
          <Text
            style={styles.textStyle}
            onPress={() => {
              setSortOrder('Position');
              setShowModal(false);
            }}
          >
            Position
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
    <View
      style={{
        ...tailwindCss('bg-white'),
        width: '100%',
        flex: 1,
      }}
    >
      {Platform.OS === 'web' && (
        <ModalWeb isVisible={showModal}>{view}</ModalWeb>
      )}

      {Platform.OS !== 'web' && (
        <Modal animationType="slide" transparent visible={showModal}>
          {view}
        </Modal>
      )}

      {status === 'Complete' && (
        <WebScrollHandler upHandler={onPressUp} downHandler={onPressDown}>
          <View
            style={tailwindCss(
              'flex-row justify-between items-center px-3 content-center',
            )}
          >
            {upDownControls && onPressUp && onPressDown && (
              <>
                <TouchableOpacity style={tailwindCss('')}>
                  <NerdButton color="bg-white" shadow="" onPress={onPressDown}>
                    <Text style={{ paddingLeft: 1, paddingRight: 1 }}>
                      <FontAwesomeIcon
                        style={tailwindCss('text-gray-600 text-xs')}
                        icon={faAngleDoubleDown}
                      />
                    </Text>
                  </NerdButton>
                </TouchableOpacity>
              </>
            )}
            <Text
              style={{
                ...tailwindCss('text-xl text-center flex-1'),
                fontFamily: 'TitilliumWeb',
              }}
            >
              Draft Summary
            </Text>

            {upDownControls && onPressUp && onPressDown && (
              <>
                <TouchableOpacity style={tailwindCss('')}>
                  <NerdButton color="bg-white" shadow="" onPress={onPressUp}>
                    <Text style={{ paddingLeft: 1, paddingRight: 1 }}>
                      <FontAwesomeIcon
                        style={tailwindCss('text-gray-600 text-xs')}
                        icon={faAngleDoubleUp}
                      />
                    </Text>
                  </NerdButton>
                </TouchableOpacity>
              </>
            )}
          </View>
        </WebScrollHandler>
      )}

      {status !== 'Complete' && (
        <WebScrollHandler upHandler={onPressUp} downHandler={onPressDown}>
          <View
            style={tailwindCss('flex-row justify-between items-center px-3')}
          >
            <TouchableOpacity
              onPress={() => {
                setViewing('Players');
              }}
            >
              <NerdText style={getViewingStyle('Players')}>Available</NerdText>
            </TouchableOpacity>
            {upDownControls && onPressUp && onPressDown && (
              <>
                <TouchableOpacity style={tailwindCss('')}>
                  <NerdButton color="bg-white" shadow="" onPress={onPressUp}>
                    <Text style={{ paddingLeft: 1, paddingRight: 1 }}>
                      <FontAwesomeIcon
                        style={tailwindCss('text-gray-600 text-xs')}
                        icon={faAngleDoubleUp}
                      />
                    </Text>
                  </NerdButton>
                </TouchableOpacity>
                <TouchableOpacity style={tailwindCss('')}>
                  <NerdButton color="bg-white" shadow="" onPress={onPressDown}>
                    <Text style={{ paddingLeft: 1, paddingRight: 1 }}>
                      <FontAwesomeIcon
                        style={tailwindCss('text-gray-600 text-xs')}
                        icon={faAngleDoubleDown}
                      />
                    </Text>
                  </NerdButton>
                </TouchableOpacity>
              </>
            )}
            <TouchableOpacity
              onPress={() => {
                setViewing('Rosters');
              }}
            >
              <NerdText style={getViewingStyle('Rosters')}>Drafted</NerdText>
            </TouchableOpacity>
          </View>
        </WebScrollHandler>
      )}

      {viewing === 'Players' && (
        <>
          <PositionToggler
            setFilter={setFilter}
            filter={filter}
            onPressDown={onPressDown}
            onPressUp={onPressUp}
          />
          <FlatList
            data={filteredPlayers}
            renderItem={renderPlayerItem}
            keyExtractor={item => `${item.id}`}
            ListFooterComponent={<View style={{ height: 250 }} />}
          />
        </>
      )}

      {viewing === 'Rosters' && (
        <>
          <WebScrollHandler upHandler={onPressUp} downHandler={onPressDown}>
            <View style={tailwindCss('flex flex-row justify-between mt-6')}>
              <View style={tailwindCss('pl-3 pb-3')}>
                <View>
                  <NerdButton
                    color="bg-gray-700"
                    padding="py-1"
                    onPress={() => {
                      navigation.navigate('Change Roster');
                    }}
                  >
                    <FontAwesomeIcon
                      style={tailwindCss('text-white text-xs')}
                      icon={faEye}
                    />
                    <Text> {rosterUser.teamName.replace('Team', '')}</Text>
                  </NerdButton>
                </View>
              </View>

              <View style={tailwindCss('pr-3 pb-3')}>
                <NerdButton
                  color="bg-gray-700"
                  padding="py-1"
                  onPress={() => {
                    setShowModal(!showModal);
                  }}
                >
                  <FontAwesomeIcon
                    style={tailwindCss('text-white text-xs')}
                    icon={faAngleDoubleDown}
                  />
                  <Text>{sortOrder}</Text>
                </NerdButton>
              </View>
            </View>
          </WebScrollHandler>
          <FlatList
            data={roster}
            renderItem={renderPlayerItem}
            keyExtractor={item => `${item.id}`}
            ListFooterComponent={<View style={{ height: 250 }} />}
          />
        </>
      )}
    </View>
  );
}

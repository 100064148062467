/* eslint-disable new-cap */
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  makeVar,
} from '@apollo/client';
import React from 'react';
import fetch from 'cross-fetch';
import { getBaseApi } from '../common/getBaseApi';

export interface INerdsApolloProps {
  jwt: string | undefined | null;
  children: JSX.Element | JSX.Element[] | string;
}

const baseAPI = getBaseApi();
export const getUri = () => `${baseAPI}/api`;
export default function NerdsApollo({ jwt, children }: INerdsApolloProps) {
  const headers = { jwt: jwt || '' };
  const link = ApolloLink.from([
    new HttpLink({
      uri: getUri(),
      headers,
      credentials: 'include',
      fetch: (...args) => fetch(...args),
    }),
  ]);

  const uri = getUri();
  const cache = new InMemoryCache();

  //  Initialize Apollo Client
  const connectToDevTools = true;
  const client = new ApolloClient({
    connectToDevTools,
    link,
    uri,
    cache,
    headers,
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export const preModalStatus = makeVar<string>('Running');

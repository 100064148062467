import React, { useState } from 'react';
import { SafeAreaView, View } from 'react-native';
import { LoginState } from '../../common/loginState';
import { tailwindCss } from '../../common/tailwind';
import NerdText from '../../components/atoms/NerdText';
import CreatePassword from './CreatePassword';
import EnterEmail from './EnterEmail';
import EnterPassword from './EnterPassword';

interface Props {
  setJwt: (jwt: any) => void;
}

export default function Login({ setJwt }: Props) {
  const [email, setEmail] = useState<string>('');
  const [loginState, setLoginState] = useState<LoginState>(LoginState.Email);

  return (
    <SafeAreaView style={tailwindCss('h-full')}>
      <View style={tailwindCss('pt-12 items-center px-4')}>
        <NerdText style={tailwindCss('text-gray-700 font-bold text-xl')}>
          Dynasty Nerds
        </NerdText>

        {loginState === LoginState.Email && (
          <EnterEmail
            email={email}
            setEmail={setEmail}
            setLoginState={setLoginState}
          />
        )}

        {loginState === LoginState.EnterExistingPassword && (
          <EnterPassword
            email={email}
            setEmail={setEmail}
            setLoginState={setLoginState}
            setJwt={setJwt}
          />
        )}

        {loginState === LoginState.CreateNewPassword && (
          <CreatePassword
            email={email}
            setEmail={setEmail}
            setLoginState={setLoginState}
            setJwt={setJwt}
          />
        )}
      </View>
    </SafeAreaView>
  );
}

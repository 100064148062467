import { getUri } from '../contexts/NerdsApollo';

export const login = (params: any) => {
  const raw = JSON.stringify(params);
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  } as RequestInit;

  const uri = `${getUri()}/login`;
  return fetch(uri, requestOptions)
    .then(response => response.text())
    .then(text => {
      return JSON.parse(text).dyn_jwt || 'error';
    })
    .catch(() => 'error');
};

export const emailExists = (email: string) => {
  const myEmail = encodeURIComponent(email);
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  } as RequestInit;

  const uri = `${getUri()}/email_exists?email=${myEmail}`;
  return fetch(uri, requestOptions)
    .then(response => response.text())
    .then(text => {
      return JSON.parse(text);
    })
    .catch(() => 'error');
};

export const createUser = (params: any) => {
  const raw = JSON.stringify(params);
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  } as RequestInit;

  const uri = `${getUri()}/create_user`;
  return fetch(uri, requestOptions)
    .then(response => response.text())
    .then(text => {
      return JSON.parse(text);
    })
    .catch(() => 'error');
};

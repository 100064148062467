/* eslint-disable import/extensions */
import { create } from 'tailwind-rn';
import styles from '../../styles.json';

const { tailwind, getColor } = create(styles);

const tailwindCss = (css: string) => {
  if (css.includes('shadow-tl'))
    return {
      ...tailwind(css.replace('shadow-tl', '')),
      shadowOffset: {
        width: 0,
        height: -2,
      },
      shadowOpacity: 0.15,
      shadowRadius: 2,
    };
  if (css.includes('shadow-t'))
    return {
      ...tailwind(css.replace('shadow-t', '')),
      shadowOffset: {
        width: 0,
        height: -2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 2,
    };
  if (css.includes('shadow-b'))
    return {
      ...tailwind(css.replace('shadow-b', '')),
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 2,
    };
  if (css.includes('shadow'))
    return {
      ...tailwind(css.replace('shadow', '')),
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.25,
      shadowRadius: 2,
    };
  return { ...tailwind(css) };
};

export { tailwindCss, getColor };

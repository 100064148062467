import React, { useState } from 'react';
import { View, Text, ActivityIndicator, TextInput } from 'react-native';
import { LoginState } from '../../common/loginState';
import { tailwindCss } from '../../common/tailwind';
import NerdText from '../../components/atoms/NerdText';
import NerdButton from '../../components/NerdButton';
import { emailExists } from '../../services/login';

interface Props {
  email: string;
  setEmail: (email: string) => void;
  setLoginState: (state: LoginState) => void;
}
export default function EnterEmail({ email, setEmail, setLoginState }: Props) {
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState(false);
  const onPress = () => {
    setLoading(true);
    emailExists(email).then(x => {
      setLoading(false);
      if (x.exists) {
        setLoginState(LoginState.EnterExistingPassword);
      } else if (x.exists === false) {
        setLoginState(LoginState.CreateNewPassword);
      } else if (x.exists === null && x.msg) {
        setMessage(x.msg);
      } else {
        setMessage('An error occurred');
      }
    });
  };
  return (
    <>
      <View
        style={tailwindCss(
          'bg-white p-3 w-full mt-6 border border-gray-300 rounded',
        )}
      >
        <TextInput
          placeholder="Email"
          onChangeText={setEmail}
          value={email}
          autoCompleteType="email"
          textContentType="username"
        />
      </View>

      <NerdButton onPress={onPress} additional="mt-3">
        {loading ? (
          <ActivityIndicator size="small" color="white" />
        ) : (
          <NerdText>Next</NerdText>
        )}
      </NerdButton>

      <View style={{ ...tailwindCss('p-2') }}>
        <NerdText>Let's start by entering your email address.</NerdText>
      </View>

      <NerdText style={tailwindCss('text-red-600 font-semibold')}>
        {message}
      </NerdText>
    </>
  );
}

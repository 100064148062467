import Constants from 'expo-constants';
import React from 'react';
import { SafeAreaView, View } from 'react-native';
import { tailwindCss } from '../../common/tailwind';
import NerdText from '../../components/atoms/NerdText';
import NerdButton from '../../components/NerdButton';

export interface IUserInfoDataViewProps {
  setJwt: (jwt: string | null) => void;
  setTitle: (title: string) => void;
}
export default function UserInfoDataView({ setJwt }: IUserInfoDataViewProps) {
  const { version } = Constants.manifest;
  return (
    <SafeAreaView style={tailwindCss('h-full m-4')}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <NerdButton
          onPress={() => {
            setJwt(null);
          }}
        >
          <NerdText>Sign Out</NerdText>
        </NerdButton>
      </View>
      <View style={tailwindCss('p-3 items-center text-sm')}>
        <NerdText>Version: {version}</NerdText>
      </View>
    </SafeAreaView>
  );
}

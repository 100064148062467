import { gql, useApolloClient } from '@apollo/client';
import {
  DraftPick,
  Player,
  useClientMockDraftSyncMutation,
} from '../generated/graphql';

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}
const tableSflex = [90, 90, 90, 90, 90];
const tablePpr = [90, 90, 90, 90];

const typeLimits = {
  // PPR
  1: { QB: 1, WR: 3, RB: 3, TE: 1, K: 0 },
  // Super-Flex / TE
  2: { QB: 2, WR: 3, RB: 3, TE: 1, K: 0 },
  // Standard
  3: { QB: 1, WR: 3, RB: 3, TE: 1, K: 0 },
  // Super-flex
  4: { QB: 2, WR: 3, RB: 3, TE: 1, K: 0 },
  // SFB
  5: { QB: 2, WR: 3, RB: 3, TE: 1, K: 0 },
};

function autoPickInner(
  overallPick: number,
  players: Array<Player>,
  typeId: number,
) {
  let r = getRandomInt(100) + 1;

  const specificChance = [1, 3].includes(typeId)
    ? tablePpr[overallPick - 1]
    : tableSflex[overallPick - 1];

  if (specificChance) {
    const isPickedStraight = r < specificChance;
    if (isPickedStraight) {
      return players[0];
    }
  }

  // if we were in specific table but didn't hit we
  // want to make sure we move down 1 minimum
  const wasSpecific = specificChance ? 1 : 0;
  r = getRandomInt(100) + 1;
  if (r > 75) {
    return players[wasSpecific];
  }

  if (r > 50) {
    return players[getRandomInt(1) + wasSpecific];
  }

  if (r > 25) {
    return players[getRandomInt(3) + 1];
  }

  return players[getRandomInt(5) + 1];
}

// if no players left end draft

// player pool = players filterd for positions that hit limits
// if no players left after filtering (revert filtering)

// -> pass player pool to random

// if random returns undefined take top player in player pool
export function autoPick(
  overallPick: DraftPick,
  allPlayers: Array<Player>,
  picked: Array<DraftPick>,
  typeId: number,
  autoPickInnerFn: (
    overallPick: number,
    players: Array<Player>,
    typeId: number,
  ) => Player | undefined,
  rookiesOnly: boolean,
) {
  const pickedIds = picked.map((dp: DraftPick) => dp.player?.id);
  const players = [...allPlayers].filter(p => !pickedIds.includes(p.id));
  let playersToUse = players.sort((a, b) => (a.order > b.order ? 1 : -1));

  // if we aren't doing a rookie draft
  // we need to remove players who
  // have hit position limits unless
  // all position limits have been hit
  let hasKicker = false;
  if (!rookiesOnly) {
    const usersPosition = picked
      .filter(p => p.userId === overallPick.userId)
      .reduce(
        (prev, current) => {
          //  const x = prev[current.player?.position]  + 1;
          if (current.player?.position === 'QB')
            return { ...prev, QB: prev.QB + 1 };

          if (current.player?.position === 'WR')
            return { ...prev, WR: prev.WR + 1 };

          if (current.player?.position === 'TE')
            return { ...prev, TE: prev.TE + 1 };

          if (current.player?.position === 'RB')
            return { ...prev, RB: prev.RB + 1 };

          if (current.player?.position === 'K')
            return { ...prev, K: prev.K + 1 };
          return prev;
        },
        { QB: 0, RB: 0, WR: 0, TE: 0, K: 0 },
      );

    const positionsMaxed = [] as Array<string>;

    if (usersPosition.QB >= (typeLimits as any)[typeId].QB) {
      positionsMaxed.push('QB');
    }

    if (usersPosition.WR >= (typeLimits as any)[typeId].WR) {
      positionsMaxed.push('WR');
    }

    if (usersPosition.RB >= (typeLimits as any)[typeId].RB) {
      positionsMaxed.push('RB');
    }

    if (usersPosition.TE >= (typeLimits as any)[typeId].TE) {
      positionsMaxed.push('TE');
    }

    if (usersPosition.K >= (typeLimits as any)[typeId].K) {
      positionsMaxed.push('K');
    }

    hasKicker = usersPosition.K > 0;

    const playersNonMaxed = players.filter(
      p => !positionsMaxed.includes(p.position),
    );

    if (playersNonMaxed.length > 0) {
      playersToUse = playersNonMaxed;
    }
  }

  const withoutKicker = hasKicker
    ? playersToUse.filter(p => p.position !== 'K')
    : playersToUse;

  const player = autoPickInnerFn(overallPick.overall, withoutKicker, typeId);
  if (player !== undefined) return player;

  if (playersToUse.length > 0) return playersToUse;

  return undefined;
}

export default function useCache() {
  const client = useApolloClient();

  const [clientMockDraftSyncMutation] = useClientMockDraftSyncMutation();
  const sync = (draftId: number) => {
    const id = `Draft:${draftId}`;
    const fragment = gql`
      fragment DraftSyncData on Draft {
        id
        status
        draftPicks {
          id
          player {
            id
            playerId
          }
          userId
          realPick
        }
      }
    `;
    const syncData = client.readFragment({ id, fragment });
    const draftPicks = syncData.draftPicks.map((dp: DraftPick) => {
      return {
        playerId: dp.player?.playerId || null,
        id: dp.id,
        userId: dp.userId,
        realPick: dp.realPick,
      };
    });

    const { __typename, ...mockDraft } = { ...syncData, draftPicks };
    clientMockDraftSyncMutation({
      variables: { mockDraft },
    });
  };

  const handleClaimUser = ({
    draftId,
    draftPickId,
    userId,
  }: {
    draftId: number;
    draftPickId: number;
    userId: number;
  }) => {
    const id = `DraftPick:${draftPickId}`;
    const pick = client.readFragment({
      id,
      fragment: gql`
        fragment DraftPickUserId on DraftPick {
          id
          userId
          originalUserId
        }
      `,
    });
    const newUserId = pick.userId === userId ? pick.originalUserId : userId;
    if (newUserId === pick.userId) return;
    client.writeFragment({
      id,
      fragment: gql`
        fragment DraftPickUserIdUpdate on DraftPick {
          userId
        }
      `,
      data: { userId: newUserId },
    });
    sync(draftId);
  };

  const handleUpdateStatus = (draftId: number, status: string) => {
    const id = `Draft:${draftId}`;
    const fragment = gql`
      fragment DraftStatus on Draft {
        status
      }
    `;
    client.readFragment({ id, fragment });
    client.writeFragment({
      id,
      fragment,
      data: { status },
    });
    sync(draftId);
  };

  const handleMakePick = (
    draftId: number,
    draftPickId: number,
    playerId: number,
    resume: boolean,
  ) => {
    const id = `Draft:${draftId}`;

    const player = client.readFragment({
      id: `Player:${playerId}`,
      fragment: gql`
        fragment SpecificPlayer on Player {
          id
          img
          name
          position
          rookie
          team
          order
          playerId
        }
      `,
    });

    const overallPick = client.readFragment({
      id: `DraftPick:${draftPickId}`,
      fragment: gql`
        fragment DraftPickOverAll on DraftPick {
          overall
        }
      `,
    });

    client.writeFragment({
      id: `DraftPick:${draftPickId}`,
      fragment: gql`
        fragment DraftPickPlayer on DraftPick {
          player
          realPick
        }
      `,
      data: { player, realPick: true },
    });

    const picks = client.readFragment({
      id,
      fragment: gql`
        fragment Picks on Draft {
          draftPicks {
            player {
              id
            }
          }
        }
      `,
    });

    const totalPicks = picks.draftPicks.length;

    const lastPick = totalPicks === overallPick.overall;

    if (lastPick) {
      handleUpdateStatus(draftId, 'Complete');
    } else if (resume) {
      handleUpdateStatus(draftId, 'Running');
    } else {
      sync(draftId);
    }
  };

  const handleUndo = (draftId: number, userId: number) => {
    const id = `Draft:${draftId}`;

    const draft = client.readFragment({
      id,
      fragment: gql`
        fragment UndoPicks on Draft {
          id
          draftPicks {
            id
            userId
            overall
            player {
              id
            }
          }
        }
      `,
    });

    const reversedPicks = [...draft.draftPicks]
      .filter(dp => dp.player !== null)
      .sort((a, b) => (a.overall < b.overall ? 1 : -1));

    let i = 0;
    const toUndo = [];
    while (true) {
      const visit = reversedPicks[i];
      if (visit === undefined) {
        break;
      }
      toUndo.push({ ...visit, player: null });
      if (visit.userId === userId) {
        break;
      }
      i += 1;
    }

    toUndo.forEach(pick => {
      client.writeFragment({
        id: `DraftPick:${pick.id}`,
        fragment: gql`
          fragment UndoPick on DraftPick {
            player
            realPick
          }
        `,
        data: { player: null, realPick: false },
      });
    });
  };

  const handleAutoPick = (draftId: number, draftPickId: number) => {
    const id = `Draft:${draftId}`;

    const draft = client.readFragment({
      id,
      fragment: gql`
        fragment DraftTypeAndPicks on Draft {
          id
          typeId
          rookiesOnly
          draftPicks {
            id
            player {
              id
              playerId
              position
            }
            userId
          }
        }
      `,
    });

    const overallPick = client.readFragment({
      id: `DraftPick:${draftPickId}`,
      fragment: gql`
        fragment MyDraftPick on DraftPick {
          id
          overall
          userId
        }
      `,
    });

    const players = client.readFragment({
      id,
      fragment: gql`
        fragment Players on Draft {
          players {
            id
            img
            name
            position
            rookie
            team
            order
            playerId
          }
        }
      `,
    });

    const totalPicks = draft.draftPicks.length;
    const lastPick = totalPicks === overallPick.overall;
    const picked = draft.draftPicks.filter(
      (dp: DraftPick) => dp.player !== null,
    );

    const { typeId } = draft;
    const player = autoPick(
      overallPick,
      players.players,
      picked,
      typeId,
      autoPickInner,
      draft.rookiesOnly,
    );
    if (player !== undefined) {
      client.writeFragment({
        id: `DraftPick:${draftPickId}`,
        fragment: gql`
          fragment DraftPickPlayer on DraftPick {
            player
            realPick
          }
        `,
        data: { player, realPick: false },
      });
    }
    if (lastPick || player === undefined) {
      handleUpdateStatus(draftId, 'Complete');
    }
  };

  const handleCompleteDraft = (draftId: number) => {
    const id = `Draft:${draftId}`;

    const draft = client.readFragment({
      id,
      fragment: gql`
        fragment UndoPicks on Draft {
          id
          draftPicks {
            id
            userId
            overall
            player {
              id
            }
          }
        }
      `,
    });

    const remainingPicks = [...draft.draftPicks].filter(
      dp => dp.player === null,
    );

    remainingPicks.forEach(pick => handleAutoPick(draftId, pick.id));

    handleUpdateStatus(draftId, 'Complete');
  };
  return {
    handleAutoPick,
    handleClaimUser,
    handleCompleteDraft,
    handleMakePick,
    handleUndo,
    handleUpdateStatus,
  };
}

import { useIsFocused, useNavigation } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Text, useWindowDimensions, View } from 'react-native';
import { getGroupedDraftPicks } from '../common/getGroupedDraftPicks';
import { tailwindCss } from '../common/tailwind';
import { Draft, DraftPick, User, UserInfo } from '../generated/graphql';
import { getName } from '../views/MockNavigationView/ChangeClaimUser';
import NerdText from './atoms/NerdText';
import DraftControlModal from './molecules/DraftControlModal';
import DraftPickGrid from './molecules/DraftPickGrid';
import NerdButton from './NerdButton';

export interface IJoinMockProps {
  mockDraft: Draft;
  countDownLegnth: number;
  startClock: any;
  userInfo: UserInfo;
  onClock: DraftPick | null;
  autoPick: (draftPickId: number) => void;
  pauseClock: (remaininMs: number) => void;
  players: any;
  claimUser: User;
  footerHeight: number;
  setTitle: (title: string) => void;
  showPauseToggle: (show: boolean) => void;
  handleClaimUser: ({
    draftId,
    draftPickId,
    userId,
  }: {
    draftId: number;
    draftPickId: number;
    userId: number;
  }) => void;
  showDraftMenu: boolean;
  setShowDraftMenu: (show: boolean) => void;
  resetClaimUser: () => void;
}

export default function JoinMock({
  mockDraft,
  startClock,
  userInfo,
  onClock,
  autoPick,
  pauseClock,
  countDownLegnth,
  players,
  claimUser,
  footerHeight,
  setTitle,
  showPauseToggle,
  handleClaimUser,
  showDraftMenu,
  setShowDraftMenu,
  resetClaimUser,
}: IJoinMockProps) {
  const { status } = mockDraft;
  const [countDown, setCountDown] = useState(countDownLegnth);
  const navigation = useNavigation();
  const getCountDown = () => {
    const chopped = countDown;
    return chopped < 10 ? `0:0${chopped}` : `0:${chopped}`;
  };

  const autoPicker = (onClock?.userId || 0) <= 0;
  const countDownDisplay = getCountDown();
  const round = onClock?.round;
  const slot = onClock?.slot;
  const getTitle = () => {
    if (status === 'Complete') {
      return `${mockDraft.key} - Draft Complete`;
    }
    if (round === undefined || slot === undefined) return '';
    if (status !== 'New') {
      return autoPicker === false
        ? `Round ${round} Pick ${slot} - ${countDownDisplay}`
        : `Round ${round} Pick ${slot}`;
    }
    return 'Setup Mock Draft';
  };

  const title = getTitle();
  useEffect(() => {
    setTitle(title);
  }, [title, setTitle]);

  const isFocused = useIsFocused();

  useEffect(() => {
    setTitle('');
  });

  useEffect(() => {
    if (!['Complete', 'New'].includes(status)) {
      showPauseToggle(isFocused);
    } else {
      showPauseToggle(false);
    }
    if (!isFocused && status === 'Running') {
      pauseClock(1000);
    }
  }, [isFocused, pauseClock, status, showPauseToggle]);

  const userId = mockDraft.status === 'New' ? claimUser.userId : userInfo.id;
  const grouped = getGroupedDraftPicks(mockDraft.draftPicks);

  const isClaiming = userId !== userInfo.id;
  const [adjustingKey, setAdjustingKey] = useState('');
  // if (isClaiming && !adjustPicks) {
  //   setAdjustPicks(true);
  // }
  // todo need to figure out better loop for player on lock
  const onClockId = onClock?.id;
  useEffect(() => {
    if (status === 'Running') {
      const timer = setTimeout(() => {
        if (countDown > 1) {
          setCountDown(countDown - 1);
        } else if (onClockId) {
          autoPick(onClockId);
        }
      }, 1000);

      return () => {
        return clearTimeout(timer);
      };
    }

    return undefined;
  }, [countDown, status, autoPick, onClockId]);

  const [starting, setStarting] = useState(false);

  const handleStartClock = () => {
    // todo replace with animation and startclock once header is removed
    setStarting(true);
    setTimeout(() => {
      startClock();
    }, 100);
  };

  const getFooter = () => (
    <View style={tailwindCss('m-3 mt-10')}>
      <NerdButton onPress={handleStartClock}>Start the Draft</NerdButton>
    </View>
  );

  const handleAdjustPicks = () => {
    navigation.navigate('Change Claim');
  };

  const adjustPicks = adjustingKey === mockDraft.key;

  const handleGridClick = (pick: any) => {
    if (mockDraft.status === 'New' && adjustPicks) {
      handleClaimUser({
        draftId: mockDraft.id,
        draftPickId: pick.id,
        userId: claimUser?.userId || 0,
      });
    }
  };

  const getHeader = () => {
    return (
      <View style={tailwindCss('m-3 mt-10')}>
        <NerdButton onPress={handleStartClock}>Start the Draft</NerdButton>
        {adjustPicks === true ? (
          <>
            <NerdButton
              onPress={handleAdjustPicks}
              additional="mt-6"
              color="bg-white"
              textColor="text-red-500"
            >
              <NerdText>
                Claim Picks for: {claimUser && getName(claimUser)}{' '}
              </NerdText>
            </NerdButton>
            <NerdText style={{ ...tailwindCss('mt-12 mx-3 p-3') }}>
              <View style={tailwindCss('mt-3 mb-1')}>
                <Text style={tailwindCss('text-base')}>
                  To claim additional picks, tap the pick.
                </Text>
              </View>
              <View style={tailwindCss('mt-3 mb-1')}>
                <Text style={tailwindCss('text-base')}>
                  To give up a pick, claim the pick as a different player.
                  Change players by tapping Claim Picks for:
                </Text>
              </View>
              <View style={tailwindCss('mt-3 mb-1')}>
                <Text style={tailwindCss('text-base')}>
                  Tapping a changed pick a second time will return the pick to
                  the original owner.
                </Text>
              </View>
            </NerdText>
          </>
        ) : (
          <NerdButton
            onPress={() => setAdjustingKey(mockDraft.key)}
            additional="mt-6"
            color="bg-white"
            textColor="text-red-500"
          >
            Adjust Picks
          </NerdButton>
        )}
      </View>
    );
  };

  const draftStatus = mockDraft.status;
  // make sure to reset the user to the logged in user when not
  // setting up draft
  useEffect(() => {
    if (claimUser.userId !== userInfo.id && draftStatus !== 'New') {
      resetClaimUser();
    }
  }, [draftStatus, userInfo, claimUser, resetClaimUser]);

  useEffect(() => {
    setCountDown(countDownLegnth);
  }, [countDownLegnth, onClockId]);

  // todo web auto scroll
  const [number, onChangeNumber] = React.useState();
  const [offset, onChangeOffset] = React.useState();
  const onClockHeader = () => {
    if (status === 'Complete')
      return (
        <View
          style={tailwindCss(
            'bg-gray-200 flex-row items-center justify-center',
          )}
        >
          <Text
            style={{
              ...tailwindCss('text-2xl m-2 text-gray-700'),
              fontFamily: 'TitilliumWebBold',
            }}
          >
            Draft Complete
          </Text>
        </View>
      );

    if (status === 'Paused') {
      return (
        <View
          style={tailwindCss(
            'bg-white flex-row items-center justify-center p-1',
          )}
        >
          <Text
            style={tailwindCss(
              'text-2xl pr-2 text-gray-700 flex-row items-center',
            )}
          >
            <Text
              style={{
                ...tailwindCss('font-bold'),
                fontFamily: 'TitilliumWebBold',
              }}
            >
              Draft:
            </Text>
            {mockDraft.key} - {mockDraft.status}
          </Text>
        </View>
      );
    }

    if (mockDraft.status !== 'New' && mockDraft.status !== 'Complete') {
      return (
        <View
          style={tailwindCss(
            'bg-white flex-row items-center justify-center p-1 pb-2',
          )}
        >
          <Text
            style={tailwindCss(
              'text-2xl pr-2 text-gray-700 flex-row items-center h-6',
            )}
          >
            {onClock?.userId !== userInfo.id && (
              <>
                <View style={tailwindCss('pr-2 items-center flex-row')}>
                  <NerdText style={{ ...tailwindCss('text-xl font-bold') }}>
                    Round {round} Pick {slot}
                  </NerdText>
                </View>
              </>
            )}
            {onClock?.userId === userInfo.id && (
              <>
                <View style={tailwindCss('pr-2 items-center flex-row')}>
                  <NerdText
                    style={{
                      ...tailwindCss('text-xl'),
                    }}
                  >
                    You&apos;re On The Clock
                  </NerdText>
                </View>

                <View style={tailwindCss('pr-2 items-center flex-row')}>
                  <NerdText
                    style={{
                      ...tailwindCss('text-xl font-bold'),
                      fontFamily: 'TitilliumWebBold',
                    }}
                  >
                    {countDownDisplay}
                  </NerdText>
                </View>
              </>
            )}
          </Text>
        </View>
      );
    }

    return null;
  };

  const modal = (
    <DraftControlModal
      userId={userId}
      mockDraftId={mockDraft.id}
      showDraftMenu={showDraftMenu}
      setShowDraftMenu={setShowDraftMenu}
    />
  );

  const maxWidth = 800;
  const windowWidth = useWindowDimensions().width;
  const respWidth =
    windowWidth > maxWidth ? { margin: 'auto', width: maxWidth } : {};
  const height = windowWidth > maxWidth ? undefined : 80;

  if (mockDraft.status === 'New') {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: 'column',
          ...respWidth,
        }}
      >
        {modal}
        {onClockHeader()}
        <DraftPickGrid
          height={height}
          userId={userId}
          groupedPicks={grouped}
          header={starting ? undefined : getHeader}
          footer={getFooter}
          players={players}
          onGridClick={handleGridClick}
          footerHeight={0}
          status={status}
          users={mockDraft.users}
        />
      </View>
    );
  }

  if (mockDraft.status === 'Paused')
    return (
      <View style={{ flex: 1, flexDirection: 'column', ...respWidth }}>
        {modal}
        {onClockHeader()}
        <DraftPickGrid
          height={height}
          scrollTo={number}
          offset={offset}
          userId={userId}
          groupedPicks={grouped}
          players={players}
          onClock={onClock}
          footerHeight={footerHeight}
          status={status}
          users={mockDraft.users}
          onGridClick={handleGridClick}
        />
      </View>
    );

  return (
    <View style={{ flex: 1, flexDirection: 'column', ...respWidth }}>
      {modal}
      {onClockHeader()}
      <DraftPickGrid
        height={height}
        userId={userId}
        groupedPicks={grouped}
        players={players}
        onClock={onClock}
        footerHeight={footerHeight}
        status={status}
        users={mockDraft.users}
        onGridClick={handleGridClick}
      />
    </View>
  );
}

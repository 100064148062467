import { faFootballBall } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useRef } from 'react';
import {
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  SectionList,
  Text,
  TouchableOpacity,
  View,
  FlatList as FlatListWeb,
} from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import { SectionGrid } from 'react-native-super-grid';
import { tailwindCss } from '../../common/tailwind';
import { DraftPick, Player, User } from '../../generated/graphql';

export interface IDraftPickGridProps {
  groupedPicks: {
    title: string;
    data: DraftPick[];
    round: number;
  }[];
  header?: () => JSX.Element;
  footer?: () => JSX.Element;
  stickyHeader?: () => JSX.Element | null;
  players: Player[];
  onClock?: DraftPick | null;
  userId: number;
  onGridClick?: any;
  footerHeight: number;
  status: string;
  users: User[];
  scrollTo?: number | undefined;
  offset?: number | undefined;
  height: number | undefined;
}

export default function DraftPickGrid({
  groupedPicks,
  header,
  footer,
  stickyHeader,
  players,
  onClock,
  userId,
  onGridClick,
  footerHeight,
  status,
  users,
  scrollTo,
  offset,
  height,
}: IDraftPickGridProps) {
  // todo user id in draftPick should not be 1

  const getPickedStyle = (item: DraftPick, player: Player | null) => {
    const colors = {
      RB: 'bg-green-500',
      QB: 'bg-red-500',
      TE: 'bg-blue-700',
      WR: 'bg-orange-500',
      K: 'bg-gray-600',
    } as any;

    const color =
      player === null ? 'text-red-500' : (colors[player?.position] as any);
    return {
      ...tailwindCss(`justify-between ${color} shadow p-2 overflow-hidden`),
      height,
      borderRadius: 3,
    };
  };
  const getPickedTextStyle = (
    item: DraftPick,
    player: Player | null,
    left: boolean,
    bold = true,
    width = false,
    log = false,
  ) => {
    const align = left ? 'self-start text-left' : 'self-end text-right';

    const fontColor = item.userId === userId ? 'text-white' : 'text-green-500';
    const color = player === null ? fontColor : '';
    const str = `text-white text-xs ${align} ${color} ${
      bold ? 'font-bold' : ''
    } ${width ? 'h-4' : ''}`;

    return {
      ...tailwindCss(str),
      fontFamily: bold ? 'TitilliumWebBold' : 'TitilliumWeb',
    };
  };

  const nameSplit = (name: string) => {
    const parts = name?.split(' ') || '';

    if (parts.length <= 1) return name;
    return [parts[0], parts.slice(1).join(' ')];
  };

  const renderPick = (pick: DraftPick) => {
    const team = users.find(u => u.userId === pick.userId);

    const player = players.find(p => p.id === pick.player?.id) as Player;
    const teamDisaply = team?.teamName?.includes('AP Team')
      ? player?.team
      : team?.teamName;
    if (pick.player && player) {
      return (
        <TouchableOpacity style={getPickedStyle(pick, player)}>
          <Text style={getPickedTextStyle(pick, player, true, true, true)}>
            {nameSplit(player?.name)[0]}
          </Text>
          <Text
            style={{
              ...getPickedTextStyle(pick, player, true, true, true),
              ...tailwindCss('overflow-hidden'),
            }}
          >
            {nameSplit(player?.name)[1]}
          </Text>
          <Text
            style={{
              ...getPickedTextStyle(pick, player, true, false, true),
              ...tailwindCss('overflow-hidden'),
            }}
          >
            {teamDisaply}
          </Text>
          <View style={tailwindCss('flex-row justify-between')}>
            <Text style={getPickedTextStyle(pick, player, false)}>
              {player.position}
            </Text>
            <Text style={getPickedTextStyle(pick, player, false)}>
              {pick.round}.{pick.slot}
            </Text>
          </View>
        </TouchableOpacity>
      );
    }

    const { teamName } = users.find(u => u.userId === pick.userId) as User;
    const bg =
      pick.userId === userId && status === 'New' ? 'bg-red-500' : 'bg-white';

    const textColor =
      pick.userId === userId && status === 'New'
        ? 'text-white'
        : 'text-red-500';

    const bold = pick.userId === userId ? 'font-bold' : '';

    const pickOnClock = pick.id === onClock?.id;

    const border = pickOnClock
      ? {
          ...tailwindCss('border-2 border-red-500'),
          borderRadius: 3,
          borderBottomWidth: 3,
          borderLeftWidth: 3,
          borderRightWidth: 3,
          borderTopWidth: 3,
        }
      : { borderRadius: 3 };

    // todo use h-5 height on web
    return (
      <TouchableOpacity
        style={{
          ...tailwindCss(`justify-between ${bg} shadow p-2  overflow-hidden `),
          ...border,
          height,
        }}
        onPress={() => onGridClick(pick)}
      >
        <Text
          style={{
            ...tailwindCss(
              `text-white text-xs ${textColor} font-bold h-4 overflow-hidden`,
            ),
            fontFamily: 'TitilliumWebBold',
          }}
        >
          {teamName}
        </Text>

        <Text style={tailwindCss('h-4 mt-1')}>
          {pick.userId === userId ? (
            <FontAwesomeIcon
              color={
                onClock ? (tailwindCss('text-red-500') as any).color : 'white'
              }
              size={23}
              icon={faFootballBall}
            />
          ) : null}
        </Text>
        <Text
          style={{
            ...tailwindCss(
              `justify-between ${textColor} self-end text-right ${bold} `,
            ),
            fontFamily: 'TitilliumWeb',
            fontSize: 12,
          }}
        >
          {pick.round}.{pick.slot}
        </Text>
      </TouchableOpacity>
    );
  };

  const myFooter = <View style={{ height: footerHeight }} />;

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    // console.log(event.nativeEvent.contentOffset.y);
  };

  const sectionGridRef = useRef<SectionList>(null);

  const hasRef = sectionGridRef !== undefined && sectionGridRef !== null;

  // this is how we control the  list
  const sectionIndex = (onClock?.round || 1) - 1;
  const itemIndex = Math.floor(((onClock?.slot || 1) - 1) / 4);
  const isNative = Platform.OS !== 'web';
  useEffect(() => {
    if (hasRef && ['Running', ' Paused'].includes(status) && isNative) {
      sectionGridRef.current?.scrollToLocation({
        animated: true,
        sectionIndex, // matches current round
        itemIndex, // 1,2 or 3
        viewOffset: 0, // 0 when itemIndex 1
        viewPosition: 0,
      });
    }
  }, [sectionIndex, itemIndex, status, hasRef, isNative]);
  // TODO web auto scroll
  // useEffect(() => {
  //   console.log(scrollTo, offset);
  //   if (scrollTo) {
  //     sectionGridRef.current?.scrollToLocation({
  //       animated: true,
  //       sectionIndex: scrollTo, // matches current round
  //       itemIndex: 1, // 1,2 or 3
  //       viewOffset: scrollTo, // 0 when itemIndex 1
  //       viewPosition: 0,
  //     });
  //   }
  // }, [scrollTo, offset]);
  const renderItem = (fItem: any) => {
    if (fItem.index === 0) return stickyHeader && stickyHeader();
    return (
      <SectionGrid
        // getItemLayout={(data, index) => {
        //   const adjusted = height - (offset || 0);
        //   return {
        //     length: adjusted,
        //     offset: adjusted * index,
        //     index,
        //   };
        // }}
        ref={sectionGridRef as any}
        onScrollToIndexFailed={() => {
          // console.log('scroll to index failed');
        }}
        onScroll={handleScroll}
        ListHeaderComponent={header}
        ListFooterComponent={status === 'New' ? footer : myFooter}
        itemDimension={height}
        stickySectionHeadersEnabled
        sections={groupedPicks}
        style={tailwindCss('mt-2 flex bg-gray-200 ')}
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => renderPick(item)}
        renderSectionHeader={({ section }) => (
          <Text
            style={{
              ...tailwindCss(
                'flex text-lg text-gray-700 font-bold px-2 bg-gray-200',
              ),
              fontFamily: 'TitilliumWebBold',
            }}
          >
            {section.title}
          </Text>
        )}
        keyExtractor={(item: any) => `${item.id}`}
        listKey="draft-pick"
      />
    );
  };

  if (stickyHeader === undefined) {
    return renderItem({ index: 1 }) as JSX.Element;
  }

  return Platform.OS === 'web' ? (
    <FlatListWeb
      renderItem={renderItem as any}
      data={[{ id: 'header' }, { id: 'grid' }]}
      listKey="holder"
      keyExtractor={(item: any) => item.id}
      ListFooterComponent={myFooter}
    />
  ) : (
    <FlatList
      renderItem={renderItem as any}
      data={[{ id: 'header' }, { id: 'grid' }]}
      listKey="holder"
      keyExtractor={(item: any) => item.id}
      ListFooterComponent={myFooter}
    />
  );
}

import { UserLeague } from '../generated/graphql';

export function getMock(
  position: number,
  rounds: number,
  size: number,
  typeId: number,
  autoLength: number,
  clockLength: number,
  rookiesOnly: boolean,
  userLeague: UserLeague | null | undefined,
  orderType: string,
) {
  if (userLeague) {
    const leagueTeamId =
      (userLeague.teams.find(t => t.owned) || userLeague.teams[0]).id || null;
    return {
      leagueId: userLeague.id,
      teamId: leagueTeamId,
      orderType: orderType === 'Linear' ? 'linear' : 'snake',
      position,
      rounds,
      size,
      typeId,
      useLeagueDraftData: true,
      autoLength,
      clockLength,
      clockMs: 1000,
      random: true,
      rookiesOnly,
    };
  }
  return {
    leagueId: null,
    teamId: null,
    orderType: orderType === 'Linear' ? 'linear' : 'snake',
    position,
    rounds,
    size,
    typeId,
    useLeagueDraftData: false,
    autoLength,
    clockLength,
    clockMs: 1000,
    random: true,
    rookiesOnly,
  };
}

import ModalWeb from 'modal-enhanced-react-native-web';
import React from 'react';
import {
  Modal,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { preModalStatus } from '../../contexts/NerdsApollo';
import useCache from '../../hooks/useCache';
import NerdText from '../atoms/NerdText';

export const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 70,
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    marginTop: 20,
    borderRadius: 20,
    padding: 10,
    elevation: 2,
  },
  redButton: {
    backgroundColor: '#990f00',
  },
  whiteButton: {
    backgroundColor: 'white',
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'TitilliumWebSemiBold',
  },
  textStyle2: {
    color: '#990f00',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'TitilliumWebSemiBold',
  },
  modalText: {
    marginBottom: 15,
    textAlign: 'center',
    fontFamily: 'TitilliumWebSemiBold',
  },
});

export interface IDraftControlModalProps {
  showDraftMenu: boolean;
  setShowDraftMenu: (show: boolean) => void;
  mockDraftId: number;
  userId: number;
}

export default function DraftControlModal({
  showDraftMenu,
  setShowDraftMenu,
  mockDraftId,
  userId,
}: IDraftControlModalProps) {
  const { handleUpdateStatus, handleUndo, handleCompleteDraft } = useCache();
  const preStatus = preModalStatus();

  const dismiss = () => {
    handleUpdateStatus(mockDraftId, preStatus);
    setShowDraftMenu(false);
  };

  const pause = () => {
    handleUpdateStatus(mockDraftId, 'Paused');
    setShowDraftMenu(false);
  };

  const resume = () => {
    handleUpdateStatus(mockDraftId, 'Running');
    setShowDraftMenu(false);
  };

  const complete = () => {
    handleCompleteDraft(mockDraftId);
    setShowDraftMenu(false);
  };

  const undo = () => {
    handleUndo(mockDraftId, userId);
    handleUpdateStatus(mockDraftId, preStatus);
    setShowDraftMenu(false);
  };

  const view = (
    <View style={styles.centeredView}>
      <View style={styles.modalView}>
        {preStatus === 'Running' && (
          <TouchableOpacity
            style={[styles.button, styles.redButton]}
            onPress={pause}
          >
            <NerdText style={styles.textStyle}>Pause Draft</NerdText>
          </TouchableOpacity>
        )}
        {preStatus === 'Paused' && (
          <TouchableOpacity
            style={[styles.button, styles.redButton]}
            onPress={resume}
          >
            <NerdText style={styles.textStyle}>Resume Draft</NerdText>
          </TouchableOpacity>
        )}

        <TouchableOpacity
          style={[styles.button, styles.redButton]}
          onPress={undo}
        >
          <NerdText style={styles.textStyle}>Undo Last Pick</NerdText>
        </TouchableOpacity>

        <TouchableOpacity
          style={[styles.button, styles.redButton]}
          onPress={complete}
        >
          <NerdText style={styles.textStyle}>Complete Draft</NerdText>
        </TouchableOpacity>

        <TouchableOpacity
          style={[styles.button, styles.whiteButton]}
          onPress={dismiss}
        >
          <NerdText style={styles.textStyle2}>Continue</NerdText>
        </TouchableOpacity>
      </View>
    </View>
  );

  if (Platform.OS === 'web') {
    return <ModalWeb isVisible={showDraftMenu}>{view}</ModalWeb>;
  }

  return (
    <Modal animationType="slide" transparent visible={showDraftMenu}>
      {view}
    </Modal>
  );
}

import React from 'react';
import { ScrollView, View } from 'react-native';
import { tailwindCss } from '../../common/tailwind';
import NerdButton from '../NerdButton';

interface IdAndName {
  id: number;
  name: string;
}
export interface IPillRadioProps<T extends IdAndName> {
  options: T[];
  value: T;
  setValue: (value: T) => void;
}

export default function PillRadio<T extends IdAndName>({
  options,
  value,
  setValue,
}: IPillRadioProps<T>) {
  const getColor = (option: T) =>
    option.id === value.id ? 'bg-red-500' : 'bg-white';
  const getTextColor = (option: T) =>
    option.id === value.id ? 'text-white' : 'text-gray-700';
  return (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      {options.map(type => (
        <View key={type.id} style={tailwindCss('h-full mx-1 p-1')}>
          <NerdButton
            paddingX="px-4"
            padding="py-3"
            onPress={() => {
              setValue(type);
            }}
            color={getColor(type)}
            textColor={getTextColor(type)}
          >
            {type.name}
          </NerdButton>
        </View>
      ))}
    </ScrollView>
  );
}
